import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS, LOCALIZATION_SHARED, LOCALIZATION_RECOVERY } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import Tooltip from "rc-tooltip";
import InfoIcon from "../../../static/img/info.svg";
import PhoneInputField from "../../shared/components/formik/PhoneInputField";

const validationSchemas = {
   default: Yup.object().shape({
      tphone: Yup.string()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .min(12, LOCALIZATION_RECOVERY.PHONE_VALIDATION)
         .max(12, LOCALIZATION_RECOVERY.PHONE_VALIDATION)
   }),
};

class RecoveryPhone extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.RECOVERY_PHONE,
      };
   }
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.RECOVERY_VERIFY,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 50,
      });
   };
   render() {
      return (
         <section className={`subsection page-address`}>
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_RECOVERY.PHONE_SUBTITLE}</h1>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ handleBlur, handleChange, errors, dirty, isSubmitting }) => (
                           <Form>
                              <div className="row form-box py-2">
                                 <div className="col-12">
                                    <Field
                                       name="tphone"
                                       label={LOCALIZATION_FORMS.LABEL_PHONE_NUMBER}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_PHONE_NUMBER}
                                       required={true}
                                       component={PhoneInputField}
                                       country="US"
                                       type="tel"
                                       maxlength="12"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       className="show-required"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row section-row py-4">
                                 <div className="col-12">
                                    <span className="note font-weight-bold color-blue">
                                       {LOCALIZATION_FORMS.TEXT_MOBILE_USAGE}
                                    </span>
                                    <span className="more-info ml-3">
                                       <span className="more-info-link">
                                          <Tooltip
                                             placement="top"
                                             trigger={["click", "hover"]}
                                             overlay={<span>{LOCALIZATION_FORMS.TEXT_MOBILE_USAGE_TOOLTIP}</span>}
                                             arrowContent={<div className="rc-tooltip-arrow-inner" />}
                                             align={{ offset: [0, -6] }}
                                          >
                                             <img
                                                src={InfoIcon}
                                                alt={LOCALIZATION_SHARED.IMG_ALT_MORE_DETAILS}
                                                width="24"
                                                height="24"
                                             />
                                          </Tooltip>
                                       </span>
                                    </span>
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default RecoveryPhone;
