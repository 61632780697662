import React, { Component } from "react";
import Register from "./routes/register";
import Profile from "./routes/profile";
import Recovery from "./routes/recovery";
import Manage from "./routes/manage";
import IDGuest from "./routes/idguest";
import IDRegistered from "./routes/idregistered";
import ChangePassword from "./routes/changepassword";
import Payment from "./routes/payment";
import { BrowserRouter, Route, Switch } from "react-router-dom";

class AppRouter extends Component {
   componentDidMount() {
      const isTouchDevice = "ontouchstart" in document.documentElement;
      const isiOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isTouchDevice) {
         document.body.classList.add("has-touch");
      } else {
         document.body.classList.add("no-touch");
      }
      if (isiOS) {
         document.body.classList.add("is-ios");
      } else {
         document.body.classList.add("no-ios");
      }
   }
   render() {
      return (
         <BrowserRouter>
            <Route path="/">
               <Route
                  children={({ match, ...rest }) => (
                     <div className="App">
                        <div id="wrapper">
                           <div id="inner">
                              <Switch>
                                 <Route exact path="/profile" component={Profile} />
                                 <Route exact path="/id-guest" component={IDGuest} />
                                 <Route exact path="/id-registered" component={IDRegistered} />
                                 <Route exact path="/recovery" component={Recovery} />
                                 <Route exact path="/manage" component={Manage} />
                                 <Route exact path="/change-password" component={ChangePassword} />
                                 <Route exact path="/payment" component={Payment} />
                                 <Route component={Register} />
                              </Switch>
                           </div>
                        </div>
                     </div>
                  )}
               />
            </Route>
         </BrowserRouter>
      );
   }
}

export default AppRouter;
