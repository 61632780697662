import React, { Component } from "react";
import { Link } from "react-router-dom";
import ForwardCarratBlue from "../../../static/img/forward-carrat-blue.svg";

import { LOCALIZATION_FORMS, LOCALIZATION_MANAGE, LOCALIZATION_SHARED } from "../../../modules/config/localization";

class ManagePanel extends Component {
   handleAddressEdit = () => {
      this.props.addressEdit();
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="row text-box mt-0">
                        <div className="col-12">
                           <div className="row align-items-center">
                              <div className="col-8">
                                 <div className="address-data-idguest">
                                    <h3>{this.props.initialValues.tfName} {this.props.initialValues.tlName}</h3>
                                    <p>
                                       {this.props.initialValues.temail}<br />
                                       {this.props.initialValues.taddress}
                                       <br />
                                       {this.props.initialValues.tapt}
                                       {this.props.initialValues.tapt && <br />}
                                       {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                       {this.props.initialValues.tzip}<br />
                                       {this.props.initialValues.tphone}
                                    </p>
                                 </div>
                              </div>
                              <div className="col-4 text-right">
                                 <button
                                    onClick={this.handleAddressEdit}
                                    type="button"
                                    className="btn btn-edit"
                                 >
                                    {LOCALIZATION_FORMS.BUTTON_EDIT}
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row form-box">
                        <div className="col-12">
                           <div className="row row-long-link-container">
                              <div className="col-12">
                                 <Link to="/change-password" className="row row-long-link align-items-center">
                                    <span className="col-10">{LOCALIZATION_MANAGE.TEXT_CHANGE_PASSWORD}</span>
                                    <span className="col-2 text-right"><img className="forward-carrat-blue" src={ForwardCarratBlue} alt={LOCALIZATION_SHARED.IMG_ALT_ARROW} /></span>
                                 </Link>
                              </div>
                           </div>
                           <div className="row row-long-link-container">
                              <div className="col-12">
                                 <Link to="/recovery" className="row row-long-link align-items-center">
                                    <span className="col-10">{LOCALIZATION_MANAGE.TEXT_ACCOUNT_RECOVERY_SETTINGS}</span>
                                    <span className="col-2 text-right"><img className="forward-carrat-blue" src={ForwardCarratBlue} alt={LOCALIZATION_SHARED.IMG_ALT_ARROW} /></span>
                                 </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default ManagePanel;
