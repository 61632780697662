import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS,
   LOCALIZATION_SHARED,
   LOCALIZATION_IDREGISTERED,
} from "../../../modules/config/localization";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import { PAGES } from "../../../modules/config/constants";
import CheckboxFieldSingle from "../../shared/components/formik/CheckboxFieldSingle";
import CheckSuccess3 from "../../../static/img/check-success-3.svg";

const validationSchemas = {
   default: Yup.object().shape({
      confirmAddress: Yup.boolean().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED).oneOf([true], LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class IDRegisteredRequestCodeMail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDREGISTERED_REQUEST_CODE_MAIL,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.IDREGISTERED_REQUEST_CODE_MAIL_SUCCESS,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 100,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDREGISTERED.PAGE_TITLE_REQUEST_CODE_BY_MAIL}</h1>
                     <p>{LOCALIZATION_IDREGISTERED.PAGE_SUBTITLE_REQUEST_CODE_BY_MAIL}</p>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row text-box">
                                 <div className="col-12">
                                    <div className="row align-items-center">
                                       <div className="col-10">
                                          <div className="address-data-idguest">
                                             <p className="strong">{LOCALIZATION_IDREGISTERED.TEXT_YOUR_MAILING_ADDRESS}</p>
                                             <p>
                                                {this.props.initialValues.taddress}
                                                <br />
                                                {this.props.initialValues.tapt}
                                                {this.props.initialValues.tapt && <br />}
                                                {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                                {this.props.initialValues.tzip}
                                             </p>
                                          </div>
                                       </div>
                                       <div className="col-2 text-right">
                                          <img src={CheckSuccess3} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12"><p className="note">{LOCALIZATION_IDREGISTERED.TEXT_INVITATION_MAIL_NOTICE}</p></div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <Field
                                          id="confirmAddress"
                                          name="confirmAddress"
                                          label={LOCALIZATION_FORMS.LABEL_MAIL_CONFIRMATION}
                                          classNameLabel="fine-print"
                                          className="checkbox-field"
                                          component={CheckboxFieldSingle}
                                       />
                                       <p>
                                          <button 
                                             disabled={isSubmitting || !isEmpty(errors) || (!dirty && this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1)} 
                                             type="submit" 
                                             className="btn btn-primary"
                                          >
                                             {LOCALIZATION_FORMS.BUTTON_VERIFY_BY_MAIL}
                                          </button>
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredRequestCodeMail;
