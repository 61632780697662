import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS,  LOCALIZATION_REGISTER } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";

const validationSchemas = {
   default: Yup.object().shape({
      temail: Yup.string()
         .email(LOCALIZATION_FORMS.VALIDATION_INVALID_EMAIL_ADDRESS)
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class Email extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.REGISTER_EMAIL,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.REGISTER_PHONE,
         completedForms: this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1 ? arrNewCF : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 62.5,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_REGISTER.PAGE_TITLE_EMAIL}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="temail"
                                       label={LOCALIZATION_FORMS.LABEL_EMAIL_ADDRESS}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_EMAIL_ADDRESS}
                                       required={true}
                                       component={InputTextField}
                                       type="email"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       className="show-required"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Email;
