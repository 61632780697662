import classnames from "classnames";
import React from "react";

const InputFeedback = ({ children }) => <div className="field-error"><div className="row"><div className="col-12">{children}</div></div></div>;

const Label = ({ error, children, classNameLabel, required, ...props }) => {
   return <label {...props}>{children}</label>;
};

const CheckboxFieldSingle = ({
   field: { name, value, onChange, onBlur, ...field }, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   className,
   label,
   required,
   classNameLabel,
   id,
   ...props
}) => {
   const error = errors[name];
   const touch = touched[name];
   const classes = classnames(
      "usps-checkbox",
      {
         "animated shake error has-error": !!error && !!touch,
      },
      className
   );
   return (
      <div className={classes}>
         {label && (
            <Label className={[classNameLabel || "d-block mb-0", required ? "is-required" : ""].join(" ")} error={error}>
               <div className="row align-items-center">
                  <div className="col-10">
                     <span className="label-text">{label}</span>
                  </div>
                  <div className="col-2">
                     <input
                        type="checkbox"
                        id={id}
                        name={name}
                        value={value}
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className="d-none"
                        {...field}
                        {...props}
                     />
                     <span className="label-checkbox"></span>
                  </div>
               </div>
            </Label>
         )}
         {touch && error && <InputFeedback>{error}</InputFeedback>}
      </div>
   );
};

export default CheckboxFieldSingle;
