import React, { Component } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { LOCALIZATION_FORMS, LOCALIZATION_PAYMENT } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";

const validationSchemas = {
   default: Yup.object().shape({
      enableMobileDevice: Yup.boolean()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .oneOf([true], LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};
class PaymentCart extends Component {
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.PAYMENT_SUCCESS,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 100,
      });
   };
   getQuantity = this.props.initialValues.cartItems.reduce((acc, obj) => {
      return acc + obj.quantity;
   }, 0);
   renderQuantity = this.getQuantity > 1 ? `${this.getQuantity} Items` : `${this.getQuantity} Item`;

   renderCartItems = this.props.initialValues.cartItems.map((item, index) => {
      return item.quantity > 0 ? (
         <div className="row sep-above py-3" key={index}>
            <div className="col-3">
               <img src={item.img} alt={item.alt} />
            </div>
            <div className="col-6">
               <p className="font-weight-bold my-0">{item.name}</p>
               <p className="fine-print">
                  {item.description}
                  <br />
                  {`Quantity: ${item.quantity}`}
                  <br />
                  {`SKU: ${item.sku}`}
               </p>
            </div>
            <div className="col-3">{`$${item.price.toFixed(2)}`}</div>
         </div>
      ) : null;
   });
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.PAYMENT_REVIEW,
      };
   }
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{this.renderQuantity}</h1>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={() => (
                           <Form>
                              <div className="row align-items-center sep-below">
                                 <div className="col-12">
                                    {this.getQuantity === 0 ? (
                                       <h1>{LOCALIZATION_PAYMENT.TEXT_EMPTY_CART}</h1>
                                    ) : (
                                       this.renderCartItems
                                    )}
                                 </div>
                              </div>
                              <div className="row mt-4">
                                 <div className="col-10">
                                    <p className="fs-14">{LOCALIZATION_PAYMENT.CART_NOTE}</p>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentCart;
