import React, { Component } from "react";
import { PAGES } from "../../modules/config/constants";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProgressBar from "../shared/components/ProgressBar";
import RecoveryHome from "./components/RecoveryHome";
import { LOCALIZATION_RECOVERY } from "../../modules/config/localization";
import RecoveryPhone from "./components/RecoveryPhone";
import RecoveryVerify from "./components/RecoveryVerify";
import RecoveryConfirm from "./components/RecoveryConfirm";
import RecoveryEdit from "./components/RecoveryEdit";
import RecoverySuccess from "./components/RecoverySuccess";

class Recovery extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // initialValues should be pulled in from DB
         initialValues: {
            progress: 0,
            page: PAGES.RECOVERY_HOME,
            backPage: PAGES.RECOVERY_HOME,
            manualBackURL: '/manage',
            tphone: "",
            verificationCode: "",
            completedForms: [],
            acceptOptIn: false,
            enableMobileDevice: false,
         },
      };
   }
   handlePageChange = values => {
      this.setState({ initialValues: values });
   };
   handleSubmit = (values) => {
      this.setState({ initialValues: values }, function () {
         console.log("account recovery stuff to db here");
      });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader
                  hideSubtitle={true}
                  handlePageChange={this.handlePageChange}
                  history={this.props.history || undefined}
                  showLogo={
                     this.state.initialValues.page === PAGES.RECOVERY_HOME ||
                     this.state.initialValues.page === PAGES.RECOVERY_EDIT
                        ? true
                        : false
                  }
                  headerTitleText={
                     this.state.initialValues.page === PAGES.RECOVERY_HOME ||
                     this.state.initialValues.page === PAGES.RECOVERY_EDIT
                        ? LOCALIZATION_RECOVERY.HOME_TITLE
                        : LOCALIZATION_RECOVERY.OTHER_TITLE
                  }
                  initialValues={this.state.initialValues}
               />
               {this.state.initialValues.page === PAGES.RECOVERY_HOME ||
               this.state.initialValues.page === PAGES.RECOVERY_EDIT ? null : (
                  <ProgressBar progress={this.state.initialValues.progress} />
               )}
            </div>
            <div className={`subsection recovery-container main-container page-` + this.state.initialValues.page}>
               <div className="subsection page-panel">
                  {this.state.initialValues.page === PAGES.RECOVERY_HOME && (
                     <RecoveryHome onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
                  )}
                  {this.state.initialValues.page === PAGES.RECOVERY_PHONE && (
                     <RecoveryPhone onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
                  )}
                  {this.state.initialValues.page === PAGES.RECOVERY_VERIFY && (
                     <RecoveryVerify onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
                  )}
                  {this.state.initialValues.page === PAGES.RECOVERY_CONFIRM && (
                     <RecoveryConfirm onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
                  )}
                  {this.state.initialValues.page === PAGES.RECOVERY_SUCCESS && (
                     <RecoverySuccess onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
                  )}
                  {this.state.initialValues.page === PAGES.RECOVERY_EDIT && (
                     /* if recovery phone # exists */ <RecoveryEdit
                        onSubmit={this.handleSubmit}
                        initialValues={this.state.initialValues}
                     />
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default Recovery;
