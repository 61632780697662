import React, { Component } from "react";
import { Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_IDGUEST,
   LOCALIZATION_SHARED,
} from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CheckSuccess3 from "../../../static/img/check-success-3.svg";

class IDGuestAddressVerify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDGUEST_ADDRESS_VERIFY,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({ ...values, page: "trigger_register", completedForms: this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1 ? arrNewCF : this.props.initialValues.completedForms, progress: 0 });
   };

   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDGUEST.PAGE_TITLE_ADDRESS_VERIFY}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row text-box">
                                 <div className="col-12">
                                    <div className="row">
                                       <div className="col-12">
                                          <p>
                                             <strong className="dark green">
                                                {LOCALIZATION_IDGUEST.ADDRESS_ELIGIBLE}
                                             </strong>
                                          </p>
                                       </div>
                                    </div>
                                    <div className="row align-items-center">
                                       <div className="col-10">
                                          <div className="address-data-idguest">
                                             {this.props.initialValues.taddress}
                                             <br />
                                             {this.props.initialValues.tapt}
                                             {this.props.initialValues.tapt && <br />}
                                             {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                             {this.props.initialValues.tzip}
                                          </div>
                                       </div>
                                       <div className="col-2 text-right">
                                          <img src={CheckSuccess3} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">{LOCALIZATION_IDGUEST.TEXT_GET_STARTED_PROCEDURE}</div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_FORMS.BUTTON_LETS_GET_STARTED}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDGuestAddressVerify;
