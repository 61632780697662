import React, { Component } from "react";
import { FORM_DEFAULTS, PAGES } from "../../modules/config/constants";
import { LOCALIZATION_PROFILE } from "../../modules/config/localization";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProfileForm from "./components/ProfileForm";

class Profile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // initialValues should be pulled in from DB
         initialValues: {
            page: PAGES.PROFILE,
            manualBackURL: '/manage',
            progress: 0,
            tfName: "Nicole",
            tlName: "Anderson",
            tmI: "",
            stitle: "",
            ssuffix: "",
            temail: "nanderson@gmail.com",
            sphoneType: "",
            tphone: "888-888-8000",
            text: "",
            tmobile: "",
            taddress: "3100 Buffalo Creek Rd",
            tapt: "",
            tcity: "Arlington",
            sstate: "VA",
            tzip: "22201",
            scountry: FORM_DEFAULTS.SCOUNTRY,
         },
      };
   }
   handleSubmit = values => {
      this.setState({ initialValues: values }, function () {
         console.log('write out profile settings data to db here');
      });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader history={this.props.history || undefined} showLogo={true} hideSubtitle={true} headerTitleText={LOCALIZATION_PROFILE.TITLE} initialValues={this.state.initialValues} />
            </div>
            <div className="subsection main-container profile-container">
               <div className="subsection page-panel">
                  <ProfileForm onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
               </div>
            </div>
         </>
      );
   }
}

export default Profile;
