import React, { Component } from "react";
import { Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_IDREGISTERED,
   LOCALIZATION_SHARED
} from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CheckSuccess3 from "../../../static/img/check-success-3.svg";


class IDRegisteredRequestCodeOnlineVerify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_VERIFY,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_VERIFICATION_ENTRY,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 90,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDREGISTERED.PAGE_TITLE_REQUEST_CODE_ONLINE_VERIFY}</h1>
                     <p>{LOCALIZATION_IDREGISTERED.PAGE_SUBTITLE_REQUEST_CODE_ONLINE_VERIFY}</p>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <div className="row section-row align-items-center">
                                       <div className="col-10">
                                          <div className="address-data-idguest">
                                             <p className="strong">{LOCALIZATION_IDREGISTERED.TEXT_MOBILE_NUMBER}</p>
                                             <p>
                                                {this.props.initialValues.tphone}
                                             </p>
                                          </div>
                                       </div>
                                       <div className="col-2 text-right">
                                          <img src={CheckSuccess3} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                                       </div>
                                    </div>
                                    <div className="row section-row sep-above align-items-center">
                                       <div className="col-10">
                                          <div className="address-data-idguest">
                                             <p className="strong">{LOCALIZATION_IDREGISTERED.TEXT_YOUR_MAILING_ADDRESS}</p>
                                             <p>
                                                {this.props.initialValues.taddress}
                                                <br />
                                                {this.props.initialValues.tapt}
                                                {this.props.initialValues.tapt && <br />}
                                                {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                                {this.props.initialValues.tzip}
                                             </p>
                                          </div>
                                       </div>
                                       <div className="col-2 text-right">
                                          <img src={CheckSuccess3} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12"><p className="note">{LOCALIZATION_IDREGISTERED.TEXT_MOBILE_VERIFY_INSTRUCTIONS}</p></div>
                              </div>
                              <div className="row button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_FORMS.BUTTON_SEND_VERIFICATION_CODE}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredRequestCodeOnlineVerify;
