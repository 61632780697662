import classnames from "classnames";
import _ from "lodash";
import { LOCALIZATION_FORMS } from "../../../../modules/config/localization";
import React, { Component } from "react";

const Label = ({ error, children, classNameLabel, required, ...props }) => {
   return <label {...props}>{children}</label>;
};

class InputPasswordField extends Component {
   constructor(props) {
      super(props);
      this.state = {
         uncovered: false,
         isFocused: false,
      };
   }
   handleUncover = () => {
      this.setState({
         uncovered: !this.state.uncovered,
      });
   };
   handleFocus = () => {
      this.setState({
         isFocused: true,
      });
   };
   handleBlur = e => {
      this.props.handleBlur(e);
      setTimeout(() => {
         this.setState({
            isFocused: false,
         });
      }, 150);
   };
   render() {
      const {
         field: { name, onBlur, ...field }, // { name, value, onChange, onBlur }
         form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
         className,
         label,
         required,
         classNameLabel,
         inputClass,
         inputTabIndex,
         showUncoverButton,
         type,
         floatingLabel,
         usernameValue,
         maxLength,
         ...props
      } = this.props;
      const error = errors[name];
      const touch = touched[name];

      const classes = classnames(
         "form-group",
         {
            "animated shake error has-error": !!error && !!touch,
            "floating-label": !!floatingLabel,
            "has-uncover-button": !!showUncoverButton,
         },
         className
      );

      const specialChars = /^[A-Za-z0-9-.@#&?'/",+()!]*$/;

      return (
         <div className={classes}>
            <input
               maxLength={maxLength}
               id={name}
               tabIndex={inputTabIndex || undefined}
               className={inputClass || "form-control"}
               type={this.state.uncovered ? "text" : type}
               onFocus={this.handleFocus}
               onBlur={this.handleBlur}
               {...field}
               {..._.omit(props, ["handleBlur"])}
            />
            {label && (
               <>
                  <div className="inline-label-wrapper">
                     {label && (
                        <Label
                           className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                           htmlFor={name}
                           error={error}
                        >
                           {label}
                        </Label>
                     )}
                  </div>
                  {this.state.isFocused && field.value && touch && error && (
                     <div className="error-bubble password-bubble">
                        <>
                           <p className="strong">{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_FAILING}</p>
                           <ul>
                              {field.value === usernameValue && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_MUST_NOT_MATCH_USERNAME}</span></li>
                              )}
                              {(field.value.length < 8 || field.value.length > 50) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_CHARACTER_COUNT}</span></li>
                              )}
                              {!/[A-Z]/.test(field.value) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_UPPERCASE}</span></li>
                              )}
                              {!/[a-z]/.test(field.value) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_LOWERCASE}</span></li>
                              )}
                              {!/[0-9]/.test(field.value) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_NUMBER}</span></li>
                              )}
                              {!specialChars.test(field.value) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_SPECIAL}</span></li>
                              )}
                              {/(.)\1\1/.test(field.value) && (
                                 <li><span>{LOCALIZATION_FORMS.VALIDATION_BUBBLE_PASSWORD_CONSECUTIVE}</span></li>
                              )}
                           </ul>
                        </>
                     </div>
                  )}
               </>
            )}
            {this.state.isFocused && showUncoverButton && field.value ? (
               <button
                  onClick={this.handleUncover}
                  type="button"
                  className={`btn btn-chromeless btn-uncover ${this.state.uncovered ? "active" : ""}`}
               >
                  <span>
                     {this.state.uncovered
                        ? LOCALIZATION_FORMS.BUTTON_PASSWORD_HIDE
                        : LOCALIZATION_FORMS.BUTTON_PASSWORD_SHOW}
                  </span>
               </button>
            ) : (
               ""
            )}
         </div>
      );
   }
}

export default InputPasswordField;
