import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { LOCALIZATION_FORMS, LOCALIZATION_RECOVERY } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CheckboxFieldSingle from "../../shared/components/formik/CheckboxFieldSingle";
import isEmpty from "lodash/isEmpty";

const validationSchemas = {
   default: Yup.object().shape({
      acceptOptIn: Yup.boolean()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .oneOf([true], LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class RecoveryConfirm extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.RECOVERY_CONFIRM,
      };
   }
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.RECOVERY_SUCCESS,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 100,
      });
   };
   formatPhone = (phone) => {
      let phoneNumberString = phone.substring(2);
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
         return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
   };
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_RECOVERY.CONFIRM_SUBTITLE}</h1>
                     <p>{LOCALIZATION_RECOVERY.CONFIRM_TEXT}</p>
                        <p className="mt-4 mb-0 font-bold">{LOCALIZATION_RECOVERY.CONFIRM_LABEL}</p>
                        <p>{this.formatPhone(this.props.initialValues.tphone)}</p>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ errors, dirty, isSubmitting }) => (
                           <Form>
                              <div className="row">
                                 <div className="col-12">
                                    <div className="row align-items-center">
                                       <div className="col-8"></div>
                                       <div className="col-4 text-right"></div>
                                    </div>
                                    <div className="row section-row p-0">
                                       <div className="col-12">
                                          <div className="sep py-3 fs-14 lh-18">
                                             <Field
                                                id="acceptOptIn"
                                                name="acceptOptIn"
                                                label={LOCALIZATION_RECOVERY.CONFIRM_CHECKBOX_LABEL}
                                                className="checkbox-field"
                                                component={CheckboxFieldSingle}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button
                                          type="submit"
                                          disabled={
                                             isSubmitting ||
                                             !isEmpty(errors) ||
                                             (!dirty &&
                                                this.props.initialValues.completedForms.indexOf(
                                                   this.state.currentForm
                                                ) === -1)
                                          }
                                          className="btn btn-primary"
                                       >
                                          {LOCALIZATION_RECOVERY.CONFIRM_NEXT_BUTTON}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default RecoveryConfirm;
