import React, { Component } from "react";
import { LOCALIZATION_FORMS } from "../../../modules/config/localization";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";

class IDRegisteredRequestCodeMailSuccess extends Component {
   handleDone = () => {
      console.log('Your backend operation for Done button click here.');
   };
   componentDidMount() {
      console.log('form values', this.props.initialValues);
   }
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="row">
                        <div className="col-12">
                           <p className="text-center">
                              <img src={CircleCheckGreen} alt="checkmark" />
                           </p>
                           <h1 className="text-center green">Your verification code has been requested</h1>
                           <p className="text-center">We have received your request for an invitation code to access Informed Delivery features for your mailing address.  Your invitation code request is being processed and will be sent via First-Class Mail.  Please return to your preferences page to enter the invitation code once it has been received.</p>
                        </div>
                     </div>
                     <div className="row button-box button-box-shadow">
                        <div className="col-12">
                           <div className="button-bar clearfix">
                              <button onClick={this.handleDone} type="button" className="btn btn-primary">
                                 {LOCALIZATION_FORMS.BUTTON_DONE}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredRequestCodeMailSuccess;
