import React, { Component } from "react";
import { LOCALIZATION_FORMS, LOCALIZATION_IDREGISTERED } from "../../../modules/config/localization";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";

class IDRegisteredRequestCodeOnlineSuccess extends Component {
   handleDone = () => {
      console.log('Your backend operation for Go to Informed Delivery button click here.');
   };
   componentDidMount() {
      console.log('form values', this.props.initialValues);
   }
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="row">
                        <div className="col-12">
                           <p className="text-center">
                              <img src={CircleCheckGreen} alt="checkmark" />
                           </p>
                           <h1 className="text-center green">{LOCALIZATION_IDREGISTERED.PAGE_TITLE_REQUEST_CODE_ONLINE_SUCCESS}</h1>
                           <p className="text-center">{LOCALIZATION_IDREGISTERED.PAGE_SUBTITLE_REQUEST_CODE_ONLINE_SUCCESS}</p>
                        </div>
                     </div>
                     <div className="row button-box button-box-shadow">
                        <div className="col-12">
                           <div className="button-bar clearfix">
                              <button onClick={this.handleDone} type="button" className="btn btn-primary">
                                 {LOCALIZATION_FORMS.BUTTON_GO_TO_INFORMED_DELIVERY}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredRequestCodeOnlineSuccess;
