import React, { Component } from "react";
import { Form, Formik } from "formik";
import {  LOCALIZATION_RECOVERY } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import hero from "../../../static/img/recovery/home-hero.svg";

class RecoveryHome extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.RECOVERY_HOME,
      };
   }
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.RECOVERY_PHONE,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 25,
      });
   };
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row mt-5">
                  <div className="col-12">
                     <p className="text-center mt-3">
                        <img src={hero} alt="Cell Phone with green checkmark" />
                     </p>
                     <p className="text-center">{LOCALIZATION_RECOVERY.HOME_TEXT}</p>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        render={() => (
                           <Form>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix p-15">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_RECOVERY.HOME_NEXT_BUTTON}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default RecoveryHome;
