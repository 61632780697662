import classnames from "classnames";
import _ from "lodash";
import { LOCALIZATION_FORMS } from "../../../../modules/config/localization";
import React, { Component } from "react";

const InputFeedback = ({ children }) => <div className="field-error-custom">{children}</div>;

const Label = ({ error, children, classNameLabel, required, ...props }) => {
   return <label {...props}>{children}</label>;
};

class InputTextField extends Component {
   constructor(props) {
      super(props);
      this.state = {
         uncovered: false,
         isFocused: false,
      };
   }
   handleUncover = () => {
      this.setState({
         uncovered: !this.state.uncovered,
      });
   };
   handleFocus = () => {
      this.setState({
         isFocused: true,
      });
   };
   handleBlur = (e) => {
      this.props.handleBlur(e);
      this.props.showUncoverButton
         ? setTimeout(() => {
              this.setState({
                 isFocused: false,
              });
           }, 150)
         : this.setState({
              isFocused: false,
           });
      ;
   };
   render() {
      const {
         field: { name, onBlur,  ...field }, // { name, value, onChange, onBlur }
         form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
         className,
         label,
         simpleTooltip,
         simpleTooltipClass,
         required,
         classNameLabel,
         inputClass,
         inputTabIndex,
         showUncoverButton,
         type,
         floatingLabel,
         errorPosition,
         autoFocus,
         maxLength,
         ...props
      } = this.props;
      const error = errors[name];
      const touch = touched[name];
      const classes = classnames(
         "form-group",
         {
            "animated shake error has-error": !!error && !!touch,
            "floating-label": !!floatingLabel,
            "has-uncover-button": !!showUncoverButton,
         },
         className
      );

      return (
         <div className={classes}>
            {!floatingLabel && (label || simpleTooltip) && (
               <>
                  <div className="inline-label-wrapper">
                     {label && (
                        <Label
                           className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                           htmlFor={name}
                           error={error}
                        >
                           {label}
                        </Label>
                     )}
                  </div>
                  {simpleTooltip && this.state.isFocused && field.value && (
                     <div className={`simple-tooltip ${simpleTooltipClass}`}>
                        <div className="simple-tooltip-inner">{simpleTooltip}</div>
                     </div>
                  )}
                  {this.state.isFocused && field.value && touch && error && (
                     <div className={`error-bubble error-bubble-${errorPosition || "standard"}`}>
                        <InputFeedback>{error}</InputFeedback>
                     </div>
                  )}
               </>
            )}
            {type === "textarea" ? (
               <textarea
                  maxLength={maxLength}
                  id={name}
                  tabIndex={inputTabIndex || undefined}
                  className={inputClass || "form-control"}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  {...field}
                  {..._.omit(props, ["textarea", "type", "handleBlur"])}
               />
            ) : (
               <input
                  maxLength={maxLength}
                  id={name}
                  tabIndex={inputTabIndex || undefined}
                  className={inputClass || "form-control"}
                  type={this.state.uncovered ? "text" : type}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  autoFocus={autoFocus ? 'autofocus' : undefined}
                  {...field}
                  {..._.omit(props, ["textarea", "handleBlur"])}
               />
            )}
            {floatingLabel && (label || simpleTooltip) && (
               <>
                  <div className="inline-label-wrapper">
                     {label && (
                        <Label
                           className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                           htmlFor={name}
                           error={error}
                        >
                           {label}
                        </Label>
                     )}
                  </div>
                  {simpleTooltip && this.state.isFocused && field.value && (
                     <div className={`simple-tooltip ${simpleTooltipClass}`}>
                        <div className="simple-tooltip-inner">{simpleTooltip}</div>
                     </div>
                  )}
                  {this.state.isFocused && field.value && touch && error && (
                     <div className={`error-bubble error-bubble-${errorPosition || "standard"}`}>
                        <InputFeedback>{error}</InputFeedback>
                     </div>
                  )}
               </>
            )}
            {this.state.isFocused && showUncoverButton && field.value ? (
               <button
                  onClick={this.handleUncover}
                  type="button"
                  className={`btn btn-chromeless btn-uncover ${this.state.uncovered ? "active" : ""}`}
               >
                  <span>
                     {this.state.uncovered
                        ? LOCALIZATION_FORMS.BUTTON_PASSWORD_HIDE
                        : LOCALIZATION_FORMS.BUTTON_PASSWORD_SHOW}
                  </span>
               </button>
            ) : (
               ""
            )}
         </div>
      );
   }
}

export default InputTextField;
