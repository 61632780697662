import ReactHtmlParser from "react-html-parser";

export const LOCALIZATION_SHARED = {
   ALT_TEXT_ERROR: "Error",
   HEADER_SUBTITLE: 'Takes about 2 minutes',
   HEADER_TITLE: 'Create Account',
   IMG_ALT_ARROW: "Arrow",
   IMG_ALT_BACK_BUTTON: 'Back',
   IMG_ALT_CHECKMARK: 'Check Mark',
   IMG_ALT_LOGO: 'USPS',
   IMG_ALT_MORE_DETAILS: 'More Details',
   IMG_ALT_SHOPPING_CART: 'Shopping Cart Icon',
   IMG_ALT_SUBTITLE_ICON: 'Clock',
   IMG_ALT_WARNING_ICON: 'Warning',
   TEXT_404: 'Please try again.',
   TEXT_SHOW_LESS_OPTIONS: 'Show less options',
   TEXT_SHOW_MORE_OPTIONS: 'Show more options',
   TITLE_404: 'Not Found',
};

export const LOCALIZATION_REGISTER = {
   LINK_READ_OUR_PRIVACY_STATEMENT: ReactHtmlParser('Read our <a class="arrow-link" href="https://about.usps.com/who/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Statement</a>'),
   PAGE_TITLE_ADDRESS: 'Enter your address',
   PAGE_TITLE_ADDRESS_VERIFY: 'Verify your address',
   PAGE_TITLE_EMAIL: 'Enter your email address',
   PAGE_TITLE_NAME: 'Enter your name',
   PAGE_TITLE_PHONE: 'Enter your phone number',
   PAGE_TITLE_SECURITY_QUESTION1: 'Choose your first security question',
   PAGE_TITLE_SECURITY_QUESTION2: 'Choose your second security question',
   PAGE_TITLE_USERNAME_PASSWORD: 'Create a username and password',
   TEXT_CONGRATS: 'Congrats',
   TEXT_UPDATE_NOTICE: 'Notice: Updates to your online account do not trigger an Official USPS Change of Address. For more information, visit ',
   TEXT_USERNAME_NOTICE: 'Note: Once you create your account with this username, you will not be able to change it.',
   TEXT_VERIFIED_VALID_ADDRESS: 'The address you’ve provided has been verified as a valid delivery address.',
   TEXT_YOUR_DELIVERABLE_ADDRESS: 'Your deliverable address:',
   TEXT_YOURE_ALL_SET: "You're All Set",
   TEXT_TAP_DONE: 'Tap "Done" to start using the app',
};

export const LOCALIZATION_MANAGE = {
   TEXT_ACCOUNT_RECOVERY_SETTINGS: "Account Recovery Settings",
   TEXT_CHANGE_PASSWORD: "Change Password",
   TITLE: 'Manage Account'
};

export const LOCALIZATION_PROFILE = {
   TITLE: 'Your Profile'
};

export const LOCALIZATION_IDGUEST = {
   ADDRESS_ELIGIBLE: "Your address is eligible",
   PAGE_SUBTITLE_ADDRESS_INELIGIBLE: "Unfortunately the address you have entered is not eligible for Informed Delivery.",
   PAGE_TITLE_ADDRESS: "What is your mailing address?",
   PAGE_TITLE_ADDRESS_INELIGIBLE: "Address is not eligible",
   PAGE_TITLE_ADDRESS_VERIFY: "Great News!  You're eligible for Informed Delivery",
   TEXT_ADDRESS_CHANGE: "If you need to make a change to the address you entered, tap \"Edit.\"",
   TEXT_ADDRESS_INELIGIBLE: "This address is not eligible",
   TEXT_GET_STARTED_PROCEDURE: "To get started seeing your mail & packages, you'll need to create an account. This will only take a few minutes.",
   TITLE: "Informed Delivery",
};

export const LOCALIZATION_IDREGISTERED = {
   ADDRESS_ELIGIBLE: "Your address is eligible",
   PAGE_SUBTITLE_ADDRESS_INELIGIBLE: "Unfortunately the address you have entered is not eligible for Informed Delivery.",
   PAGE_SUBTITLE_REQUEST_CODE_BY_MAIL: "Please review your mailing address and confirm the statement below.",
   PAGE_SUBTITLE_REQUEST_CODE_ONLINE_NOT_FOUND: "You can still verify by mail, or edit the details you provided.",
   PAGE_SUBTITLE_REQUEST_CODE_ONLINE_SUCCESS: "Congratulations, you're all set.   You'll start seeing your packages and real pictures of your incoming mail as they become available.",
   PAGE_SUBTITLE_REQUEST_CODE_ONLINE_VERIFICATION_ENTRY: "Enter the verification code that was sent to your mobile phone.",
   PAGE_SUBTITLE_REQUEST_CODE_ONLINE_VERIFY: "Please review your mobile number and mailing address to request a One Time Passcode",
   PAGE_TITLE_ADDRESS: "What is your mailing address?",
   PAGE_TITLE_ADDRESS_VERIFY: "Is this the address where you receive mail?",
   PAGE_TITLE_ADDRESS_INELIGIBLE: "Address is not eligible",
   PAGE_TITLE_IDENTITY: "How would you like to verify your identity?",
   PAGE_TITLE_REQUEST_CODE_BY_MAIL: "Request Code by Mail",
   PAGE_TITLE_REQUEST_CODE_ONLINE: "What is your mobile phone number?",
   PAGE_TITLE_REQUEST_CODE_ONLINE_NOT_FOUND: "An identity match was not found for this number",
   PAGE_TITLE_REQUEST_CODE_ONLINE_SUCCESS: "You're now enrolled in Informed Delivery",
   PAGE_TITLE_REQUEST_CODE_ONLINE_VERIFICATION_ENTRY: "Enter your verification code",
   PAGE_TITLE_REQUEST_CODE_ONLINE_VERIFY: "Verify your address online by mobile phone",
   TEXT_ADDRESS_CHANGE: "If you need to make a change to the address you entered, tap \"Edit.\"",
   TEXT_ADDRESS_INELIGIBLE: "This address is not eligible",
   TEXT_DEFAULT_TO_MAIL_CONFIRMATION: "Once confirmed, your invitation code will be mailed via First-Class Mail.",
   TEXT_HAVING_YOUR_VERIFICATION_CODE_MAILED: "Having your verification code mailed to the address indicated in your profile takes more than a day.",
   TEXT_INVITATION_MAIL_NOTICE: "Once confirmed, your invitation code will be mailed via First-Class Mail.",
   TEXT_MOBILE_NUMBER: "Mobile Phone Number",
   TEXT_MOBILE_VERIFY_INSTRUCTIONS: 'To continue online mobile phone verification, tap "Send Verification Code" to be sent a text message containing a passcode which you\'ll enter on the next screen.',
   TEXT_ONLINE_VERIFICATION: "Online verification takes just a few moments.  Domestic U.S. Mobile phone request",
   TEXT_REQUEST_CODE_BY_MAIL: "Request Code by Mail",
   TEXT_TERMS_AGREE: "I have read, understand and agree to the Terms And Conditions for Informed Delivery. By checking this box and accepting, I acknowlenge that I amd optin in to the Informed Delivery feature, and I warrant and represent that I am eligible to receive mail that the address indicated in my profile (shown above)",
   TEXT_TERMS_CONDITIONS_LINK: ReactHtmlParser('Read the Informed Delivery <a class="arrow-link" href="https://reg.usps.com/entreg/assets/html/informed-delivery.html" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a>'),
   TEXT_VERIFICATION_DELAY: "In some cases, verification codes can take more than 1-2 minutes to arrive.",
   TEXT_VERIFY_IDENTITY_ONLINE: "Verify Identity Online",
   TEXT_YOUR_MAILING_ADDRESS: "Your Mailing Address",
   TITLE: "Informed Delivery",
};

export const LOCALIZATION_FORMS = {
   BUTTON_DONE: "Done",
   BUTTON_EDIT: "Edit",
   BUTTON_FINISH: "Finish",
   BUTTON_GO_TO_INFORMED_DELIVERY: "Go to Informed Delivery",
   BUTTON_LETS_GET_STARTED: "Let's Get Started",
   BUTTON_NEXT: "Next",
   BUTTON_PASSWORD_HIDE: 'Hide',
   BUTTON_PASSWORD_SHOW: 'Show',
   BUTTON_SAVE: "Save",
   BUTTON_SAVE_PROFILE: "Save Profile",
   BUTTON_SEND_VERIFICATION_CODE: "Send Verification Code",
   BUTTON_SEND_VERIFICATION_CODE_BY_MAIL: "Send Verification Code by Mail",
   BUTTON_TOUR_THE_APP: "Tour the App",
   BUTTON_VERIFY_BY_MAIL: "Send Verification Code by Mail",
   LABEL_APT: "Apt/Suite/Other",
   LABEL_CITY: "City",
   LABEL_EMAIL_ADDRESS: "Email Address",
   LABEL_EXT: "Ext",
   LABEL_FIRST_NAME: "First Name",
   LABEL_ID_OPT_IN: ReactHtmlParser('I accept, and opt in for Informed Delivery<sup>&reg;</sup>'),
   LABEL_LAST_NAME: "Last Name",
   LABEL_MAIL_CONFIRMATION: "By checking this box and accepting, I acknowledge that I warrant and represent that I am eligible to receive mail at this address indicated in my profile.",
   LABEL_MI: "M.I.",
   LABEL_MOBILE: "Mobile (U.S. Only)",
   LABEL_PASSWORD: "Password",
   LABEL_PHONE_NUMBER: 'Phone number',
   LABEL_STATE: "State",
   LABEL_STREET_ADDRESS: "Street address",
   LABEL_SUFFIX: "Suffix",
   LABEL_TITLE: "Title",
   LABEL_USERNAME: "Username",
   LABEL_VERIFICATION_CODE: "Enter code",
   LABEL_YOUR_ANSWER: 'Your Answer',
   LABEL_ZIP_CODE: "ZIP Code",
   OPTION_TEXT_DR: "Dr",
   OPTION_TEXT_I: 'I',
   OPTION_TEXT_II: 'II',
   OPTION_TEXT_III: 'III',
   OPTION_TEXT_IV: 'IV',
   OPTION_TEXT_JR: 'Jr',
   OPTION_TEXT_MISS: "Miss",
   OPTION_TEXT_MR: "Mr",
   OPTION_TEXT_MRS: "Mrs",
   OPTION_TEXT_MS: "Ms",
   OPTION_TEXT_SECURITY_QUESTION_1: 'In what city were you born?',
   OPTION_TEXT_SECURITY_QUESTION_2: 'What is the name of your pet?',
   OPTION_TEXT_SECURITY_QUESTION_3: 'What is your favorite food to eat?',
   OPTION_TEXT_SECURITY_QUESTION_4: 'What is your favorite holiday?',
   OPTION_TEXT_SECURITY_QUESTION_5: 'What is your favorite movie?',
   OPTION_TEXT_SECURITY_QUESTION_6: 'What is your favorite sport?',
   OPTION_TEXT_SECURITY_QUESTION_7: "What is your mother's maiden name?",
   OPTION_TEXT_SR: 'Sr',
   OPTION_TEXT_SUFFIX: 'Suffix',
   OPTION_TEXT_TITLE: "Title",
   OPTION_TEXT_V: 'V',
   PLACEHOLDER_APT: "Apt/Suite/Other",
   PLACEHOLDER_CITY: "City",
   PLACEHOLDER_EMAIL_ADDRESS: "Email Address",
   PLACEHOLDER_EXT: "Ext",
   PLACEHOLDER_FIRST_NAME: "First Name",
   PLACEHOLDER_LAST_NAME: "Last Name",
   PLACEHOLDER_MI: "M.I.",
   PLACEHOLDER_MOBILE: "Mobile (U.S. Only)",
   PLACEHOLDER_PASSWORD: "Password",
   PLACEHOLDER_PHONE_NUMBER: 'Phone number',
   PLACEHOLDER_STREET_ADDRESS: "Street address",
   PLACEHOLDER_USERNAME: "Username",
   PLACEHOLDER_VERIFICATION_CODE: "Enter code",
   PLACEHOLDER_YOUR_ANSWER: 'Your Answer',
   PLACEHOLDER_ZIP_CODE: "ZIP Code",
   TEXT_MOBILE_USAGE: 'What will my mobile number be used for?',
   TEXT_MOBILE_USAGE_TOOLTIP: 'Lorem ipsum dolor sit amet',
   TOOLTIP_USERNAMES_CASE_SENSITIVE: 'Please note that usernames are case sensitive',
   VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_LOWERCASE: "Have at least one lower case letter",
   VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_NUMBER: "Have at least one number",
   VALIDATION_BUBBLE_PASSWORD_AT_LEAST_ONE_UPPERCASE: "Have at least one upper case letter",
   VALIDATION_BUBBLE_PASSWORD_CHARACTER_COUNT: 'Be 8 to 50 characters long',
   VALIDATION_BUBBLE_PASSWORD_CONSECUTIVE: 'Not contain more than 2 consecutive repeat characters',
   VALIDATION_BUBBLE_PASSWORD_FAILING: 'Your password must:',
   VALIDATION_BUBBLE_PASSWORD_MUST_NOT_MATCH_USERNAME: "Not match your username",
   VALIDATION_BUBBLE_PASSWORD_SPECIAL: 'Only allowable special characters (if used) - () . & @ ? ‘ # , / “ + !',
   VALIDATION_INVALID_EMAIL_ADDRESS: "Please enter a valid email address",
   VALIDATION_MUST_NOT_MATCH_FIRST_QUESTION: 'Must not match your first security question',
   VALIDATION_PASSWORD_AT_LEAST_ONE_LOWERCASE: "at least one lowercase char",
   VALIDATION_PASSWORD_AT_LEAST_ONE_NUMBER: "at least one number",
   VALIDATION_PASSWORD_AT_LEAST_ONE_UPPERCASE: "at least one uppercase char",
   VALIDATION_PASSWORD_CONSECUTIVE: 'Cannot contain more than 2 consecutive repeated characters',
   VALIDATION_PASSWORD_MATCH: 'Must match your password above',
   VALIDATION_PASSWORD_MUST_NOT_MATCH_USERNAME: "Must not match your username",
   VALIDATION_PASSWORD_SPECIAL: 'Only allowable special characters (if used) - () . & @ ? ‘ # , / “ + !',
   VALIDATION_PASSWORD_TOO_LONG: "Password must be 50 characters or less",
   VALIDATION_PASSWORD_TOO_SHORT: "Password must be at least 8 characters",
   VALIDATION_REQUIRED: "Required",
   VALIDATION_SECRET_ANSWER_MIN_LENGTH: 'Your Secret Answers must be at least 3 characters long.',
   VALIDATION_USERNAME_INVALID: "Invalid username.",
   VALIDATION_USERNAME_TOO_SHORT: "Must be at least six characters."
};

export const LOCALIZATION_RECOVERY = {
   CONFIRM_CHECKBOX_LABEL: "Enable my mobile device for account recovery",
   CONFIRM_LABEL: "Mobile Number",
   CONFIRM_NEXT_BUTTON: "Activate Account Recovery",
   CONFIRM_SUBTITLE: "Confirm and opt-in to account recovery",
   CONFIRM_TEXT: "Please review the details below and check the box to enable account recovery",
   EDIT_LABEL: "Mobile Phone #",
   EDIT_NEXT_BUTTON: "Save",
   EDIT_TEXT: "Enabling mobile phone validation allows you to recover your account via text message in the event your account becomes locked.",
   HOME_NEXT_BUTTON: "Set Up Account Recovery",
   HOME_TEXT: "Enabling mobile phone validation allows you to recover your account via text message in the event your account becomes locked.",
   HOME_TITLE: "Account Recovery",
   OTHER_TITLE: "Set Up Account Recovery",
   PHONE_PLACEHOLDER: '(555) 555-5555',
   PHONE_SUBTITLE: "What is your mobile phone number?",
   PHONE_VALIDATION: "Phone number must be 10 digits",
   SUCCESS_NEXT_BUTTON: "Done",
   SUCCESS_SUBTITLE: "You can now recover your account via text message in the event your account becomes locked",
   SUCCESS_TITLE: "Account Recovery is Enabled",
   VERIFY_NOTE: "If you received multiple passcodes, only the most recent will work. Please keep in mind that it may take some time for the latest passcode to arrive.",
   VERIFY_SUBTITLE: "Enter your verification code",
   VERIFY_TEXT: "The Postal Service has sent a passcode via text message to the phone number you entered: "
};

export const LOCALIZATION_CHANGE_PASSWORD = {
   CONFIRM_NEW_PASSWORD_LABEL: "Confirm New Password",
   CONFIRM_NEW_PASSWORD_PLACEHOLDER: "Confirm New Password",
   CURRENT_PASSWORD_LABEL: "Current Password",
   CURRENT_PASSWORD_PLACEHOLDER: "Current Password",
   HOME_NEXT_BUTTON: "Change Password",
   HOME_TEXT: "Please confirm your existing password, and provide the new password for your account",
   HOME_TITLE: "Change Password",
   NEW_PASSWORD_LABEL: "New Password",
   NEW_PASSWORD_PLACEHOLDER: "New Password",
   SUCCESS_NEXT_BUTTON: "Done",
   SUCCESS_SUBTITLE: "Please remember your new password for the next time you sign in",
   SUCCESS_TITLE: "Your Password has been updated"
}

export const LOCALIZATION_PAYMENT = {
   AMEX: "AMEX",
   AMEX_FIRST_DIGIT: "3",
   BUTTON_DONE: "Done",
   BUTTON_PLACE_ORDER: "Place Order",
   BUTTON_SAVE_BILLING_ADDRESS: "Save Billing Address",
   BUTTON_SAVE_PAYMENT_METHOD: "Save Payment Method",
   BUTTON_SAVE_SHIPPING_ADDRESS: "Save Shipping Address",
   CART_NOTE: "Note: To change your order, return to the Stamps tab in the USPS mobile app.",
   CONFIRMATION_NUM: "Confirmation #",
   DINERS: "DINERS_CLUB",
   DINERS_FIRST_DIGITS: "36",
   DISCOVER: "DISCOVER",
   DISCOVER_FIRST_DIGIT: "6",
   ENDING: "ending **",
   HEADING_BILLING: "Your Billing Address",
   HEADING_METHOD: "Enter a Payment Method",
   HEADING_REVIEW: "Review Your Order",
   HEADING_SHIPPING: "Your Shipping Address",
   HEADING_SUCCESS: "Your order has been placed",
   JCB: "JCB",
   JCB_FIRST_DIGITS: "35",
   LABEL_BILLING_ADDRESS: "Billing Address",
   LABEL_CC_NUMBER: "Credit Card Number",
   LABEL_CVV: "CVV",
   LABEL_EXP_DATE: "Valid Until",
   LABEL_ITEMS: "Items",
   LABEL_NAME: "Name or Company",
   LABEL_PAYMENT_METHOD: "Payment Method",
   LABEL_SHIPPING_ADDRESS: "Shipping Address",
   MASTERCARD: "MASTERCARD",
   MASTERCARD_FIRST_DIGIT: "5",
   OR: "OR",
   OTHER: "Other",
   PLACEHOLDER_BILLING_ADDRESS: "Billing Address",
   PLACEHOLDER_CC_NUMBER: "Credit Card Number",
   PLACEHOLDER_CCNUMBER: "Credit Card Number",
   PLACEHOLDER_CVV: "CVV",
   PLACEHOLDER_EXP_DATE: "Valid Until",
   PLACEHOLDER_ITEMS: "Items",
   PLACEHOLDER_NAME: "Name or Company",
   PLACEHOLDER_PAYMENT_METHOD: "Payment Method",
   PLACEHOLDER_SHIPPING_ADDRESS: "Shipping Address",
   SHIP_TO_BILLING: "Ship to Billing Address",
   STATUS_CODE_CART_EXPIRED: 2,
   STATUS_CODE_INVALID_PAYMENT_TYPE: 5,
   STATUS_CODE_INVALID_SHIPPING_ADDRESS: 3,
   STATUS_CODE_INVALID_TOKEN: 1,
   STATUS_CODE_INVALID_VALUE: 7,
   STATUS_CODE_MISSING_PAYMENT_INFO: 4,
   STATUS_CODE_PAYMENT_FAILED: 6,
   STATUS_CODE_SUCCESS: 0,
   TEXT_ADD_PAYMENT: "Add a payment method",
   TEXT_CARTAPI_ERROR_HEADING: "Something went wrong",
   TEXT_CARTAPI_ERROR_SUBHEADING: "There was an error retrieving your information. Please try again later",
   TEXT_EMPTY_CART: "Your cart is empty",
   TEXT_ITEM: "Item",
   TEXT_ITEMS: "Items",
   TEXT_ORDER_ERROR_CART_EXPIRED: "Your cart has expired",
   TEXT_ORDER_ERROR_GENERIC: "Please try again",
   TEXT_ORDER_ERROR_HEADING: "There was a problem",
   TEXT_ORDER_ERROR_INVALID_PAYMENT_TYPE: "Invalid payment type",
   TEXT_ORDER_ERROR_MISSING_PAYMENT_INFO: "Payment info is missing or invalid",
   TEXT_ORDER_ERROR_PAYMENT_FAILED: "Payment method failed",
   TEXT_ORDER_ERROR_SHIPPING: "Shipping address is invalid",
   TEXT_ORDER_ERROR_SUBHEADING: "Please review your details and try again",
   TITLE: "Checkout",
   TOTAL: "Total",
   VALIDATION_CC_NUM: "Credit Card number must be 16 digits",
   VALIDATION_EXP_DATE: "Must be mmyy format",
   VISA: "VISA",
   VISA_FIRST_DIGIT: "4"
}