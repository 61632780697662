import React, { Component } from "react";
import BackCarratBlue from "../../../static/img/back-carrat-blue.svg";
import Clock from "../../../static/img/clock.svg";
import Logo from "../../../static/img/logo.svg";
import { LOCALIZATION_SHARED, LOCALIZATION_RECOVERY } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";

class GlobalHeader extends Component {
   handleBackButtonClick = (values) => {
      let newBackPage = '';
      switch (this.props.initialValues.backPage) {
         case PAGES.REGISTER_SECURITY_QUESTION1:
            newBackPage = PAGES.REGISTER_USERNAME_PASSWORD;
            break;
         case PAGES.REGISTER_SECURITY_QUESTION2:
            newBackPage = PAGES.REGISTER_SECURITY_QUESTION1;
            break;
         case PAGES.REGISTER_NAME:
            newBackPage = PAGES.REGISTER_SECURITY_QUESTION2;
            break;
         case PAGES.REGISTER_EMAIL:
            newBackPage = PAGES.REGISTER_NAME;
            break;
         case PAGES.REGISTER_PHONE:
            newBackPage = PAGES.REGISTER_EMAIL;
            break;
         case PAGES.REGISTER_ADDRESS:
            newBackPage = PAGES.REGISTER_PHONE;
            break;
         case PAGES.REGISTER_ADDRESS_VERIFY:
         case PAGES.REGISTER_CONGRATS:
            newBackPage = PAGES.REGISTER_ADDRESS;
            break;
         case PAGES.IDGUEST_INELIGIBLE:
            newBackPage = PAGES.IDGUEST_ADDRESS;
            break;
         case PAGES.IDREGISTERED_ADDRESS_VERIFY:
            newBackPage = PAGES.IDREGISTERED_ADDRESS;
            break;
         case PAGES.IDREGISTERED_IDENTITY:
            newBackPage = PAGES.IDREGISTERED_ADDRESS;
            break;
         case PAGES.IDREGISTERED_INELIGIBLE:
            newBackPage = PAGES.IDREGISTERED_ADDRESS;
            break;
         case PAGES.IDREGISTERED_REQUEST_CODE_MAIL:
         case PAGES.IDREGISTERED_REQUEST_CODE_ONLINE:
            newBackPage = PAGES.IDREGISTERED_IDENTITY;
            break;
         case PAGES.REGISTER_USERNAME_PASSWORD:
         case PAGES.IDGUEST_ADDRESS:
         default:
            newBackPage = "";
            break;
      }
      if (this.props.initialValues.manualBackURL){
         this.props.history.push(this.props.initialValues.manualBackURL);
      } else {
         this.props.handlePageChange({
            ...this.props.initialValues,
            page: this.props.initialValues.backPage,
            backPage: newBackPage
         });
      }
   };
   render() {
      return (
         <header className="section" id="header">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-2 header-left">
                     {this.props.initialValues && ((this.props.initialValues.backPage && this.props.initialValues.page) || this.props.initialValues.manualBackURL) && 
                        this.props.initialValues.page !== PAGES.REGISTER_USERNAME_PASSWORD &&
                        this.props.initialValues.page !== PAGES.IDGUEST_ADDRESS &&
                        this.props.initialValues.page !== PAGES.PAYMENT_REVIEW &&
                        this.props.initialValues.page !== PAGES.IDREGISTERED_ADDRESS_VERIFY &&
                        this.props.initialValues.page.indexOf('success') === -1 &&
                        this.props.initialValues.page !== PAGES.REGISTER_CONGRATS && (
                           <button type="button" className="btn btn-chromeless" onClick={this.handleBackButtonClick}>
                              <img src={BackCarratBlue} alt={LOCALIZATION_SHARED.IMG_ALT_BACK_BUTTON} />
                           </button>
                        )}
                  </div>
                  <div className={`col-8 header-center text-center ${(this.props.headerTitleText === LOCALIZATION_RECOVERY.OTHER_TITLE) ? 'p-0' : ''}`}>
                     <div className="subsection header-logo-title">
                        {this.props.showLogo && (
                           <div id="logo">
                              <img src={Logo} alt={LOCALIZATION_SHARED.IMG_ALT_LOGO} />
                           </div>
                        )}
                        <h1>{this.props.headerTitleText || LOCALIZATION_SHARED.HEADER_TITLE}</h1>
                     </div>
                     {!this.props.hideSubtitle && (
                        <div className="subsection header-subtitle">
                           <p className="uppercase">
                              <img src={Clock} alt={LOCALIZATION_SHARED.IMG_ALT_SUBTITLE_ICON} />
                              <span>{LOCALIZATION_SHARED.HEADER_SUBTITLE}</span>
                           </p>
                        </div>
                     )}
                  </div>
                  <div className="col-2 header-right"></div>
               </div>
            </div>
         </header>
      );
   }
}
export default GlobalHeader;
