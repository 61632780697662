import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_IDREGISTERED,
   LOCALIZATION_SHARED
} from "../../../modules/config/localization";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import { PAGES } from "../../../modules/config/constants";
import Tooltip from "rc-tooltip";
import InfoIcon from "../../../static/img/info.svg";
import PhoneInputField from "../../shared/components/formik/PhoneInputField";

const validationSchemas = {
   default: Yup.object().shape({
      tphone: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class IDRegisteredAddressOnline extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDREGISTERED_REQUEST_CODE_ONLINE,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_VERIFY,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 80,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDREGISTERED.PAGE_TITLE_REQUEST_CODE_ONLINE}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="tphone"
                                       label={LOCALIZATION_FORMS.LABEL_PHONE_NUMBER}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_PHONE_NUMBER}
                                       required={true}
                                       component={PhoneInputField}
                                       country="US"
                                       type="tel"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       className="show-required"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">
                                    <span className="note">{LOCALIZATION_FORMS.TEXT_MOBILE_USAGE} </span>
                                    <span className="more-info">
                                       <span className="more-info-link">
                                          <Tooltip
                                             placement="top"
                                             trigger={["click", "hover"]}
                                             overlay={<span>{LOCALIZATION_FORMS.TEXT_MOBILE_USAGE_TOOLTIP}</span>}
                                             arrowContent={<div className="rc-tooltip-arrow-inner" />}
                                             align={{ offset: [0, -6] }}
                                          >
                                             <img
                                                src={InfoIcon}
                                                alt={LOCALIZATION_SHARED.IMG_ALT_MORE_DETAILS}
                                                width="18"
                                                height="18"
                                             />
                                          </Tooltip>
                                       </span>
                                    </span>
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredAddressOnline;
