import classnames from "classnames";
import Tooltip from "rc-tooltip";
import React, { Component } from "react";
import { LOCALIZATION_SHARED } from "../../../../modules/config/localization";
import InfoIcon from "../../../../static/img/info.svg";

const InputFeedback = ({ children }) => <div className="field-error">{children}</div>;

const Label = ({ error, children, classNameLabel, required, ...props }) => {
   return <label {...props}>{children}</label>;
};

class SelectBox extends Component {

   render() {
      const {
         field: { name, value, onChange, ...field }, // { name, value, onChange, onBlur }
         form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
         className,
         label,
         required,
         tooltip,
         inputClass,
         excludeOption,
         floatingLabel,
         classNameLabel,
         readOnly,
      } = this.props;
      const error = errors[name];
      const touch = touched[name];
      const classes = classnames(
         "form-group",
         {
            "animated shake error has-error": !!error && !!touch,
            "floating-label": !!floatingLabel,
            "select-has-value": !!value,
         },
         className
      );

      return (
         <div className={classes}>
            {!floatingLabel && (label || tooltip) && (
               <div className="inline-label-wrapper">
                  {label && (
                     <Label
                        className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                        htmlFor={name}
                        error={error}
                     >
                        {label}
                     </Label>
                  )}
                  {tooltip && (
                     <div className="more-info">
                        <span className="more-info-link">
                           <Tooltip
                              placement="top"
                              trigger={["click", "hover"]}
                              overlay={<span>{tooltip}</span>}
                              arrowContent={<div className="rc-tooltip-arrow-inner" />}
                              align={{ offset: [0, -6] }}
                           >
                              <img
                                 src={InfoIcon}
                                 alt={LOCALIZATION_SHARED.IMG_ALT_MORE_DETAILS}
                                 width="18"
                                 height="18"
                              />
                           </Tooltip>
                        </span>
                     </div>
                  )}
               </div>
            )}
            <select
               rel={excludeOption}
               defaultValue={value || undefined}
               className={inputClass || "form-control"}
               id={name}
               onChange={onChange}
               disabled={readOnly || undefined}
               {...field}
            >
               {React.Children.map(this.props.children, (child, i) => {
                  if (child.props.value !== excludeOption) {
                     if(!readOnly || (readOnly && child.props.value === value)){
                        return (
                           <option id={`o${child.props.value}`} value={child.props.value}>
                              {child.props.children}
                           </option>
                        );
                     }
                  }
               })}
            </select>
            {value !== '' && floatingLabel && (label || tooltip) && (
               <div className="inline-label-wrapper">
                  {label && (
                     <Label
                        className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                        htmlFor={name}
                        error={error}
                     >
                        {label}
                     </Label>
                  )}
                  {tooltip && (
                     <div className="more-info">
                        <span className="more-info-link">
                           <Tooltip
                              placement="top"
                              trigger={["click", "hover"]}
                              overlay={<span>{tooltip}</span>}
                              arrowContent={<div className="rc-tooltip-arrow-inner" />}
                              align={{ offset: [0, -6] }}
                           >
                              <img
                                 src={InfoIcon}
                                 alt={LOCALIZATION_SHARED.IMG_ALT_MORE_DETAILS}
                                 width="18"
                                 height="18"
                              />
                           </Tooltip>
                        </span>
                     </div>
                  )}
               </div>
            )}
            {touch && error && <InputFeedback>{error}</InputFeedback>}
         </div>
      );
   }
}

export default SelectBox;
