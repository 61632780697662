import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { getIn } from 'formik';
import classnames from "classnames";
import Tooltip from "rc-tooltip";

const InputFeedback = ({ children }) => <div className="field-error-custom">{children}</div>;

const Label = ({ error, children, classNameLabel, required, ...props }) => {
   return <label {...props}>{children}</label>;
};

const PhoneInputField = (props) => {
   const {
      className,
      field: { name, value },
      form: { errors, setFieldValue, touched },
      label,
      tooltip,
      required,
      classNameLabel,
      country,
      onChange,
      disabled,
      floatingLabel,
      autoFocus,
   } = props;

   const error = errors[name];

   const [isFocused] = useState(false);
   const isError = getIn(touched, name) && getIn(errors, name);
   const errorStyle = isError ? 'error has-error' : '';
   const filledStyle = (isFocused || value) ? 'filled' : '';
   const disabledStyle = disabled ? 'disabled' : '';


   const classes = classnames(
      "form-group",
      {
         "floating-label": !!floatingLabel,
      },
      className
   );


   const onValueChange = (phoneNumber) => {
      setFieldValue(name, phoneNumber);

      if (onChange !== null) {
         onChange(phoneNumber);
      }
   };

   return (
      <div
         className={`${className} ${classes} ${errorStyle} ${filledStyle} ${disabledStyle} text-input-group phone-input`}
      >
         {!floatingLabel && (label || tooltip) && (
            <div className="inline-label-wrapper">
               {label && (
                  <Label
                     className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                     htmlFor={name}
                     error={error}
                  >
                     {label}
                  </Label>
               )}
               {tooltip && (
                  <div className="more-info">
                     <span className="more-info-link">
                        <Tooltip
                           placement="top"
                           trigger={["click", "hover"]}
                           overlay={<span>{tooltip}</span>}
                           arrowContent={<div className="rc-tooltip-arrow-inner" />}
                           align={{ offset: [0, -6] }}
                        >
                           (?)
                        </Tooltip>
                     </span>
                  </div>
               )}
            </div>
         )}

         <PhoneInput
            placeholder="Enter phone number"
            name={name}
            value={value}
            onChange={onValueChange}
            defaultCountry={country}
            flagUrl="https://flag.pk/flags/4x3/{xx}.svg"
            autoFocus={autoFocus}
         />

         {floatingLabel && (label || tooltip) && (
            <div className="inline-label-wrapper">
               {label && (
                  <Label
                     className={[classNameLabel || "", required ? "is-required" : ""].join(" ")}
                     htmlFor={name}
                     error={error}
                  >
                     {label}
                  </Label>
               )}
               {tooltip && (
                  <div className="more-info">
                     <span className="more-info-link">
                        <Tooltip
                           placement="top"
                           trigger={["click", "hover"]}
                           overlay={<span>{tooltip}</span>}
                           arrowContent={<div className="rc-tooltip-arrow-inner" />}
                           align={{ offset: [0, -6] }}
                        >
                           (?)
                        </Tooltip>
                     </span>
                  </div>
               )}
               {isError && (
                  <div className="error-bubble">
                     <InputFeedback>getIn(errors, name)</InputFeedback>
                  </div>
               )}
            </div>
         )}
      </div>
   );
};

PhoneInputField.propTypes = {
   className: PropTypes.string,
   form: PropTypes.any.isRequired,
   field: PropTypes.any.isRequired,
   onChange: PropTypes.func,
   label: PropTypes.string,
   country: PropTypes.string,
   disabled: PropTypes.bool,
};

PhoneInputField.defaultProps = {
   className: '',
   label: '',
   onChange: null,
   country: 'US',
   disabled: false,
};

export default PhoneInputField;