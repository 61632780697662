import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import InputTextField from "../../shared/components/formik/InputTextField";
import * as Yup from "yup";
import { LOCALIZATION_FORMS, LOCALIZATION_PAYMENT } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import PayPal from "../../../static/img/paypal.png";
import Venmo from "../../../static/img/venmo.png";

const validationSchemas = {
   default: Yup.object().shape({
      paymentMethodCCNumber: Yup.string()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .min(4, LOCALIZATION_PAYMENT.VALIDATION_CC_NUM)
         .max(16, LOCALIZATION_PAYMENT.VALIDATION_CC_NUM),
      paymentMethodExpDate: Yup.string()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .min(4, LOCALIZATION_PAYMENT.VALIDATION_EXP_DATE)
         .max(4, LOCALIZATION_PAYMENT.VALIDATION_EXP_DATE),
      paymentMethodCVV: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      paymentMethodName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class PaymentMethod extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.PAYMENT_METHOD,
      };
   }

   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.PAYMENT_REVIEW,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_PAYMENT.HEADING_METHOD}</h1>
                     <div className="row form-box py-3">
                        <div className="col-6 pr-2">
                           <button className="external-payment-method py-3" type="button">
                              <img src={PayPal} alt="Paypal" />
                           </button>
                        </div>
                        <div className="col-6 pl-2">
                           <button className="external-payment-method py-3" type="button">
                              <img src={Venmo} alt="Venmo" />
                           </button>
                        </div>
                     </div>
                     <p className="text-center my-3">{LOCALIZATION_PAYMENT.OR}</p>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ handleBlur, handleChange }) => (
                           <Form>
                              <div className="row form-box mt-0">
                                 <div className="col-12">
                                    <Field
                                       name="paymentMethodCCNumber"
                                       label={LOCALIZATION_PAYMENT.LABEL_CC_NUMBER}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_CC_NUMBER}
                                       required={true}
                                       autoFocus="autofocus"
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                                 <div className="col-6">
                                    <Field
                                       name="paymentMethodExpDate"
                                       label={LOCALIZATION_PAYMENT.LABEL_EXP_DATE}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_EXP_DATE}
                                       required={true}
                                       autoFocus="autofocus"
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                                 <div className="col-6">
                                    <Field
                                       name="paymentMethodCVV"
                                       label={LOCALIZATION_PAYMENT.LABEL_CVV}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_CVV}
                                       required={true}
                                       autoFocus="autofocus"
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                                 <div className="col-12">
                                    <Field
                                       name="paymentMethodName"
                                       label={LOCALIZATION_PAYMENT.LABEL_NAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_NAME}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_PAYMENT.BUTTON_SAVE_PAYMENT_METHOD}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentMethod;
