import React, { Component } from "react";

class Spinner extends Component {
   render() {
      return (
         <span className={`spinner ${this.props.className}`}>
            <svg className="spinner-canvas" viewBox="25 25 50 50">
               <circle
                  className="spinner-path"
                  cx="50"
                  cy="50"
                  r="20"
                  fill="none"
                  strokeWidth="2"
                  strokeMiterlimit="10"
               />
            </svg>
         </span>
      );
   }
}
export default Spinner;
