import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_IDREGISTERED,
} from "../../../modules/config/localization";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import { PAGES } from "../../../modules/config/constants";
import CheckboxFieldSingle from "../../shared/components/formik/CheckboxFieldSingle";

const validationSchemas = {
   default: Yup.object().shape({
      acceptOptIn: Yup.boolean().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED).oneOf([true], LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class IDRegisteredAddressVerify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDREGISTERED_ADDRESS_VERIFY,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.IDREGISTERED_IDENTITY,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 50,
      });
   };
   handleAddressEdit = () => {
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.IDREGISTERED_ADDRESS_EDIT,
         backPage: PAGES.IDREGISTERED_ADDRESS_VERIFY,
         progress: 25,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDREGISTERED.PAGE_TITLE_ADDRESS_VERIFY}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row text-box">
                                 <div className="col-12">
                                    <div className="row align-items-center">
                                       <div className="col-8">
                                          <div className="address-data-idguest">
                                             {this.props.initialValues.taddress}
                                             <br />
                                             {this.props.initialValues.tapt}
                                             {this.props.initialValues.tapt && <br />}
                                             {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                             {this.props.initialValues.tzip}
                                          </div>
                                       </div>
                                       <div className="col-4 text-right">
                                          <button
                                             onClick={this.handleAddressEdit}
                                             type="button"
                                             className="btn btn-outline-primary"
                                          >
                                             {LOCALIZATION_FORMS.BUTTON_EDIT}
                                          </button>
                                       </div>
                                    </div>
                                    <div className="row section-row">
                                       <div className="col-12">
                                          <div className="sep">{LOCALIZATION_IDREGISTERED.TEXT_TERMS_AGREE}</div>
                                       </div>
                                    </div>
                                    <div className="row section-row">
                                       <div className="col-12">
                                          <div className="sep">
                                             <Field
                                                id="acceptOptIn"
                                                name="acceptOptIn"
                                                label={LOCALIZATION_FORMS.LABEL_ID_OPT_IN}
                                                className="checkbox-field"
                                                component={CheckboxFieldSingle}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">{LOCALIZATION_IDREGISTERED.TEXT_TERMS_CONDITIONS_LINK}</div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredAddressVerify;
