import React, { Component } from "react";
import { PAGES, FORM_DEFAULTS } from "../../modules/config/constants";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProgressBar from "../shared/components/ProgressBar";
import UsernamePassword from "./components/UsernamePassword";
import SecurityQuestion1 from "./components/SecurityQuestion1";
import SecurityQuestion2 from "./components/SecurityQuestion2";
import Name from "./components/Name";
import Email from "./components/Email";
import Phone from "./components/Phone";
import Address from "./components/Address";
import AddressVerify from "./components/AddressVerify";
import Congrats from "./components/Congrats";

class Register extends Component {
   constructor(props) {
      super(props);
      this.state = {
         initialValues: {
            page: PAGES.REGISTER_USERNAME_PASSWORD,
            backPage: PAGES.REGISTER_USERNAME_PASSWORD,
            progress: 0,
            tuserName: "",
            password: "",
            passwordConfirm: "",
            ssec1: "1",
            ssec2: "1",
            tsecAnswer1: "",
            tsecAnswer2: "",
            tfName: "",
            tlName: "",
            tmI: "",
            stitle: "",
            ssuffix: "",
            temail: "",
            sphoneType: "",
            tphone: "",
            text: "",
            tmobile: "",
            taddress: (this.props.location.state && this.props.location.state.addressData.taddress) || "",
            tapt: (this.props.location.state && this.props.location.state.addressData.tapt) || "",
            tcity: (this.props.location.state && this.props.location.state.addressData.tcity) || "",
            sstate: (this.props.location.state && this.props.location.state.addressData.sstate) || "",
            tzip: (this.props.location.state && this.props.location.state.addressData.tzip) || "",
            scountry: (this.props.location.state && this.props.location.state.addressData.scountry) || FORM_DEFAULTS.SCOUNTRY,
            completedForms: (this.props.location.state && this.props.location.state.addressData.taddress) ? [PAGES.REGISTER_ADDRESS] : []
         },
      };
   }
   handlePageChange = values => {
      this.setState({ initialValues: values });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader handlePageChange={this.handlePageChange} initialValues={this.state.initialValues} />
               <ProgressBar progress={this.state.initialValues.progress} />
            </div>
            <div className="subsection main-container register-container">
               <div className="subsection page-panel">
                  {this.state.initialValues.page === PAGES.REGISTER_USERNAME_PASSWORD && (
                     <UsernamePassword initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_SECURITY_QUESTION1 && (
                     <SecurityQuestion1 initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_SECURITY_QUESTION2 && (
                     <SecurityQuestion2 initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_NAME && (
                     <Name initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_EMAIL && (
                     <Email initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_PHONE && (
                     <Phone initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {(this.state.initialValues.page === PAGES.REGISTER_ADDRESS ||
                     this.state.initialValues.page === PAGES.REGISTER_ADDRESS_EDIT) && (
                     <Address initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_ADDRESS_VERIFY && (
                     <AddressVerify initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.REGISTER_CONGRATS && (
                     <Congrats initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default Register;
