import React, { Component } from "react";
import { PAGES, FORM_DEFAULTS } from "../../modules/config/constants";
import { LOCALIZATION_IDREGISTERED } from "../../modules/config/localization";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProgressBar from "../shared/components/ProgressBar";
import IDRegisteredAddress from "./components/IDRegisteredAddress";
import IDRegisteredAddressVerify from "./components/IDRegisteredAddressVerify";
import IDRegisteredIdentity from "./components/IDRegisteredIdentity";
import IDRegisteredIneligible from "./components/IDRegisteredIneligible";
import IDRegisteredRequestCodeMail from "./components/IDRegisteredRequestCodeMail";
import IDRegisteredRequestCodeMailSuccess from "./components/IDRegisteredRequestCodeMailSuccess";
import IDRegisteredRequestCodeOnline from "./components/IDRegisteredRequestCodeOnline";
import IDRegisteredRequestCodeOnlineNotFound from "./components/IDRegisteredRequestCodeOnlineNotFound";
import IDRegisteredRequestCodeOnlineSuccess from "./components/IDRegisteredRequestCodeOnlineSuccess";
import IDRegisteredRequestCodeOnlineVerify from "./components/IDRegisteredRequestCodeOnlineVerify";
import IDRegisteredRequestCodeOnlineVerificationEntry from "./components/IDRegisteredRequestCodeOnlineVerificationEntry";



class IDRegistered extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // These default values should be filled from app/service
         initialValues: {
            page: PAGES.IDREGISTERED_ADDRESS_VERIFY,
            backPage: PAGES.IDREGISTERED_ADDRESS_VERIFY,
            progress: 0,
            taddress: "3100 Buffalo Creek Rd",
            tapt: "",
            tcity: "Arlington",
            sstate: "VA",
            tzip: "22201-3523",
            tphone: "",
            acceptOptIn: false,
            scountry: FORM_DEFAULTS.SCOUNTRY,
            verifyMethod: 0,
            verificationCode: '',
            confirmAddress: false,
            completedForms: [],
         },
      };
   }
   handlePageChange = values => {
      this.setState({ initialValues: values });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader
                  handlePageChange={this.handlePageChange}
                  headerTitleText={LOCALIZATION_IDREGISTERED.TITLE}
                  initialValues={this.state.initialValues}
               />
               <ProgressBar progress={this.state.initialValues.progress} />
            </div>
            <div className="subsection main-container register-container">
               <div className="subsection page-panel">
                  {(this.state.initialValues.page === PAGES.IDREGISTERED_ADDRESS ||
                     this.state.initialValues.page === PAGES.IDREGISTERED_ADDRESS_EDIT) && (
                     <IDRegisteredAddress initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_ADDRESS_VERIFY && (
                     <IDRegisteredAddressVerify initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_IDENTITY && (
                     <IDRegisteredIdentity initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_INELIGIBLE && (
                     <IDRegisteredIneligible initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_MAIL && (
                     <IDRegisteredRequestCodeMail initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_ONLINE && (
                     <IDRegisteredRequestCodeOnline initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_MAIL_SUCCESS && (
                     <IDRegisteredRequestCodeMailSuccess initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_SUCCESS && (
                     <IDRegisteredRequestCodeOnlineSuccess initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_VERIFY && (
                     <IDRegisteredRequestCodeOnlineVerify initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_VERIFICATION_ENTRY && (
                     <IDRegisteredRequestCodeOnlineVerificationEntry initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDREGISTERED_REQUEST_CODE_ONLINE_NOT_FOUND && (
                     <IDRegisteredRequestCodeOnlineNotFound initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default IDRegistered;
