import React, { Component } from "react";
import { PAGES } from "../../modules/config/constants";
import GlobalHeader from "../shared/components/GlobalHeader";
import ChangePasswordHome from "./components/ChangePasswordHome";
import { LOCALIZATION_CHANGE_PASSWORD } from "../../modules/config/localization";
import ChangePasswordSuccess from "./components/ChangePasswordSuccess";

class ChangePassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         initialValues: {
            page: PAGES.CHANGE_PASSWORD_HOME,
            backPage: PAGES.MANAGE,
            manualBackURL: '/manage',
            currentPassword: "",
            newPassword: "",
            newPasswordConfirm: "",
            completedForms:
               this.props.location.state && this.props.location.state.addressData.taddress
                  ? [PAGES.REGISTER_ADDRESS]
                  : [],
         },
      };
   }
   handlePageChange = (values) => {
      this.setState({ initialValues: values });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader
                  hideSubtitle={true}
                  history={this.props.history || undefined}
                  showLogo={this.state.initialValues.page === PAGES.CHANGE_PASSWORD_HOME ? true : false}
                  headerTitleText={LOCALIZATION_CHANGE_PASSWORD.HOME_TITLE}
                  initialValues={this.state.initialValues}
               />
            </div>
            <div className="subsection main-container register-container">
               <div className="subsection page-panel">
                  {this.state.initialValues.page === PAGES.CHANGE_PASSWORD_HOME && (
                     <ChangePasswordHome initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.CHANGE_PASSWORD_SUCCESS && (
                     <ChangePasswordSuccess initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default ChangePassword;
