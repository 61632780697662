import React, { Component } from "react";
import SelectBox from "../../shared/components/formik/SelectBox";
import InputTextField from "../../shared/components/formik/InputTextField";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS,  LOCALIZATION_REGISTER } from "../../../modules/config/localization";
import { PAGES, FORM_FIELD_VALUES } from "../../../modules/config/constants";

const validationSchemas = {
   default: Yup.object().shape({
      ssec1: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tsecAnswer1: Yup.string()
         .min(3, LOCALIZATION_FORMS.VALIDATION_SECRET_ANSWER_MIN_LENGTH)
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class SecurityQuestion1 extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.REGISTER_SECURITY_QUESTION1,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.REGISTER_SECURITY_QUESTION2,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         progress: 25,
         backPage: this.state.currentForm,
         ssec2:
            this.props.initialValues.completedForms.indexOf(PAGES.REGISTER_SECURITY_QUESTION2) === -1
               ? values.ssec1 === "1"
                  ? "2"
                  : "1"
               : values.ssec2,
         // if the first question has been changed to what the second question was, must select a new default for question2 and clear its answer
         tsecAnswer2:
            this.props.initialValues.completedForms.indexOf(PAGES.REGISTER_SECURITY_QUESTION2) === -1
               ? ""
               : values.ssec1 === values.ssec2
               ? ""
               : values.tsecAnswer2,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_REGISTER.PAGE_TITLE_SECURITY_QUESTION1}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleChange, handleBlur, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="ssec1"
                                       required={true}
                                       component={SelectBox}
                                       className="fancy-select mb-0"
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_1} id="o1">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_1}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_2} id="o2">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_2}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_3} id="o3">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_3}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_4} id="o4">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_4}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_5} id="o5">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_5}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_6} id="o6">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_6}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SECURITY_QUESTION_7} id="o7">
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SECURITY_QUESTION_7}
                                       </option>
                                    </Field>
                                    <Field
                                       name="tsecAnswer1"
                                       label={LOCALIZATION_FORMS.LABEL_YOUR_ANSWER}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_YOUR_ANSWER}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       floatingLabel={true}
                                       errorPosition="top"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default SecurityQuestion1;
