import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import SelectBox from "../../shared/components/formik/SelectBox";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS, LOCALIZATION_SHARED } from "../../../modules/config/localization";
import { FORM_FIELD_VALUES } from "../../../modules/config/constants";
import { STATES } from "../../../modules/objects/states";
import PhoneInputField from "../../shared/components/formik/PhoneInputField";

const validationSchemas = {
   default: Yup.object().shape({
      tfName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tlName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      temail: Yup.string()
         .email(LOCALIZATION_FORMS.VALIDATION_INVALID_EMAIL_ADDRESS)
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tphone: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      taddress: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tcity: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      sstate: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tzip: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class ProfileForm extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
      };
   }
   handleAccordionToggle = () => {
      this.setState({ showMoreOptions: !this.state.showMoreOptions });
   };
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      this.props.onSubmit(values);
      setSubmitting(false);
      resetForm(values);
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box mt-0">
                                 <div className="col-12">
                                    <Field
                                       name="stitle"
                                       label={LOCALIZATION_FORMS.LABEL_TITLE}
                                       floatingLabel={true}
                                       component={SelectBox}
                                       className={`fancy-select mb-0 ${
                                          this.state.showMoreOptions ? "hidden-option-visible py-2" : "hidden"
                                       }`}
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">{LOCALIZATION_FORMS.OPTION_TEXT_TITLE}</option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MRS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MRS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MISS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MISS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_DR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_DR}
                                       </option>
                                    </Field>
                                    <Field
                                       name="tfName"
                                       label={LOCALIZATION_FORMS.LABEL_FIRST_NAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_FIRST_NAME}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tmI"
                                       label={LOCALIZATION_FORMS.LABEL_MI}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_MI}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className={this.state.showMoreOptions ? "hidden-option-visible py-2" : "hidden"}
                                       floatingLabel={true}
                                       maxLength={1}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tlName"
                                       label={LOCALIZATION_FORMS.LABEL_LAST_NAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_LAST_NAME}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="ssuffix"
                                       label={LOCALIZATION_FORMS.LABEL_SUFFIX}
                                       floatingLabel={true}
                                       component={SelectBox}
                                       className={`fancy-select mb-0 py-2 ${
                                          this.state.showMoreOptions ? "hidden-option-visible" : "hidden"
                                       }`}
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">{LOCALIZATION_FORMS.OPTION_TEXT_SUFFIX}</option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_JR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_JR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_I}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_I}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_II}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_II}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_III}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_III}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_IV}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_IV}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_V}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_V}
                                       </option>
                                    </Field>
                                 </div>
                              </div>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="taddress"
                                       label={LOCALIZATION_FORMS.LABEL_STREET_ADDRESS}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_STREET_ADDRESS}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tapt"
                                       label={LOCALIZATION_FORMS.LABEL_APT}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_APT}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tcity"
                                       label={LOCALIZATION_FORMS.LABEL_CITY}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_CITY}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="sstate"
                                       label={LOCALIZATION_FORMS.LABEL_STATE}
                                       required={true}
                                       component={SelectBox}
                                       className="show-required fancy-select mb-0 py-2"
                                       floatingLabel={true}
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">* {LOCALIZATION_FORMS.LABEL_STATE}</option>
                                       {STATES &&
                                          Object.keys(STATES).map(function (key) {
                                             return (
                                                <option value={key} key={key}>
                                                   {STATES[key]}
                                                </option>
                                             );
                                          })}
                                    </Field>
                                    <Field
                                       name="tzip"
                                       label={LOCALIZATION_FORMS.LABEL_ZIP_CODE}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_ZIP_CODE}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="temail"
                                       label={LOCALIZATION_FORMS.LABEL_EMAIL_ADDRESS}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_EMAIL_ADDRESS}
                                       required={true}
                                       component={InputTextField}
                                       type="email"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tphone"
                                       label={LOCALIZATION_FORMS.LABEL_PHONE_NUMBER}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_PHONE_NUMBER}
                                       required={true}
                                       component={PhoneInputField}
                                       type="tel"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="text"
                                       label={LOCALIZATION_FORMS.LABEL_EXT}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_EXT}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className={this.state.showMoreOptions ? "hidden-option-visible py-2" : "hidden"}
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tmobile"
                                       label={LOCALIZATION_FORMS.LABEL_MOBILE}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_MOBILE}
                                       component={InputTextField}
                                       type="tel"
                                       inputClass="simple"
                                       className={this.state.showMoreOptions ? "hidden-option-visible py-2" : "hidden"}
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">
                                    <button
                                       type="button"
                                       onClick={this.handleAccordionToggle}
                                       className={`font-bold btn-accordion btn-accordion-${
                                          this.state.showMoreOptions ? "open" : "closed"
                                       }`}
                                    >
                                       {this.state.showMoreOptions
                                          ? LOCALIZATION_SHARED.TEXT_SHOW_LESS_OPTIONS
                                          : LOCALIZATION_SHARED.TEXT_SHOW_MORE_OPTIONS}
                                    </button>
                                 </div>
                              </div>
                              {!isSubmitting && isEmpty(errors) && dirty && (
                                 <div className="row button-box button-box-shadow">
                                    <div className="col-12">
                                       <div className="button-bar clearfix">
                                          <button type="submit" className="btn btn-primary btn-save-profile">
                                             {LOCALIZATION_FORMS.BUTTON_SAVE_PROFILE}
                                          </button>
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default ProfileForm;
