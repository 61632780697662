import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import InputPasswordField from "../../shared/components/formik/InputPasswordField";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS, LOCALIZATION_REGISTER } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";

const validationSchemas = {
   default: Yup.object().shape({
      tuserName: Yup.string()
         .min(6, LOCALIZATION_FORMS.VALIDATION_USERNAME_TOO_SHORT)
         .matches(/^(?=.*[a-zA-Z\d])(\w|[.@\-?,&''/_""]){6,50}$/, LOCALIZATION_FORMS.VALIDATION_USERNAME_INVALID)
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      password: Yup.string()
         .min(8, LOCALIZATION_FORMS.VALIDATION_PASSWORD_TOO_SHORT)
         .max(50, LOCALIZATION_FORMS.VALIDATION_PASSWORD_TOO_LONG)
         .matches(/[a-z]/, LOCALIZATION_FORMS.VALIDATION_PASSWORD_AT_LEAST_ONE_LOWERCASE)
         .matches(/[A-Z]/, LOCALIZATION_FORMS.VALIDATION_PASSWORD_AT_LEAST_ONE_UPPERCASE)
         .matches(/[0-9]/, LOCALIZATION_FORMS.VALIDATION_PASSWORD_AT_LEAST_ONE_NUMBER)
         .matches(/^[A-Za-z0-9-.@#&?'/",+()!]*$/, LOCALIZATION_FORMS.VALIDATION_PASSWORD_SPECIAL)
         .test("match", LOCALIZATION_FORMS.VALIDATION_PASSWORD_MUST_NOT_MATCH_USERNAME, function(password) {
            return password !== this.parent.tuserName;
         })
         .test("match", LOCALIZATION_FORMS.VALIDATION_PASSWORD_CONSECUTIVE, function(password) {
            return !/(.)\1\1/.test(password);
         })
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      passwordConfirm: Yup.string()
         .test("match", LOCALIZATION_FORMS.VALIDATION_PASSWORD_MATCH, function(passwordConfirm) {
            return passwordConfirm === this.parent.password;
         })
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class UsernamePassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.REGISTER_USERNAME_PASSWORD,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.REGISTER_SECURITY_QUESTION1,
         backPage: this.state.currentForm,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         progress: 12.5,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_REGISTER.PAGE_TITLE_USERNAME_PASSWORD}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleChange, handleBlur, errors, dirty, isSubmitting }) => (
                           <Form autoComplete="off">
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="tuserName"
                                       label={LOCALIZATION_FORMS.LABEL_USERNAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_USERNAME}
                                       required={true}
                                       component={InputTextField}
                                       autoFocus="autofocus"
                                       type="text"
                                       className="py-2"
                                       inputClass="simple"
                                       floatingLabel={true}
                                       inputTabIndex={1}
                                       simpleTooltip={
                                          /[A-Z]/.test(values.tuserName)
                                             ? LOCALIZATION_FORMS.TOOLTIP_USERNAMES_CASE_SENSITIVE
                                             : undefined
                                       }
                                       simpleTooltipClass="warning"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="password"
                                       label={LOCALIZATION_FORMS.LABEL_PASSWORD}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_PASSWORD}
                                       showUncoverButton={true}
                                       required={true}
                                       component={InputPasswordField}
                                       usernameValue={values.tuserName}
                                       type="password"
                                       className="py-2"
                                       inputClass="simple"
                                       floatingLabel={true}
                                       inputTabIndex={2}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="passwordConfirm"
                                       label="Confirm Password"
                                       placeholder="Confirm Password"
                                       showUncoverButton={true}
                                       required={true}
                                       component={InputTextField}
                                       type="password"
                                       className="py-2"
                                       inputClass="simple"
                                       floatingLabel={true}
                                       inputTabIndex={3}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <p className="note mt-3">{LOCALIZATION_REGISTER.TEXT_USERNAME_NOTICE}</p>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default UsernamePassword;
