export const PAGES = {
   IDGUEST_ADDRESS: 'idguest_address',
   IDGUEST_ADDRESS_VERIFY: 'idguest_address_verify',
   IDGUEST_INELIGIBLE: 'idguest_ineligible',
   IDREGISTERED_ADDRESS: 'idregistered_address',
   IDREGISTERED_ADDRESS_EDIT: 'idregistered_address_edit',
   IDREGISTERED_ADDRESS_VERIFY: 'idregistered_address_verify',
   IDREGISTERED_IDENTITY: 'idregistered_identity',
   IDREGISTERED_INELIGIBLE: 'idregistered_ineligible',
   IDREGISTERED_REQUEST_CODE_MAIL: 'idregistered_request_code_mail',
   IDREGISTERED_REQUEST_CODE_MAIL_SUCCESS: 'idregistered_request_code_mail_success',
   IDREGISTERED_REQUEST_CODE_ONLINE: 'idregistered_request_code_online',
   IDREGISTERED_REQUEST_CODE_ONLINE_NOT_FOUND: 'idregistered_request_code_online_not_found',
   IDREGISTERED_REQUEST_CODE_ONLINE_SUCCESS: 'idregistered_request_code_online_success',
   IDREGISTERED_REQUEST_CODE_ONLINE_VERIFICATION_ENTRY: "idregistered_request_code_online_verification_entry",
   IDREGISTERED_REQUEST_CODE_ONLINE_VERIFY: 'idregistered_request_code_online_verify',
   MANAGE: 'manage',
   PROFILE: 'profile',
   REGISTER_ADDRESS: 'register_address',
   REGISTER_ADDRESS_EDIT: 'register_address_edit',
   REGISTER_ADDRESS_VERIFY: 'register_address_verify',
   REGISTER_CONGRATS: 'register_congrats',
   REGISTER_EMAIL: 'register_email',
   REGISTER_NAME: 'register_name',
   REGISTER_PHONE: 'register_phone',
   REGISTER_RETURN: 'register_return',
   REGISTER_SECURITY_QUESTION1: 'register_security_question1',
   REGISTER_SECURITY_QUESTION2: 'register_security_question2',
   REGISTER_USERNAME_PASSWORD: 'register_username_password',
   RECOVERY_HOME: 'account_recovery_home',
   RECOVERY_PHONE: 'account_recovery_phone',
   RECOVERY_VERIFY: 'account_recovery_verify',
   RECOVERY_CONFIRM: 'account_recovery_confirm',
   RECOVERY_EDIT: 'account_recovery_edit',
   RECOVERY_SUCCESS: 'account_recovery_success',
   CHANGE_PASSWORD_HOME: 'change_password_home',
   CHANGE_PASSWORD_SUCCESS: 'change_password_success',
   PAYMENT_REVIEW: 'payment_review',
   PAYMENT_CART: 'payment_cart',
   PAYMENT_SHIPPING: 'payment_shipping',
   PAYMENT_BILLING: 'payment_billing',
   PAYMENT_METHOD: 'payment_method',
   PAYMENT_SUCCESS: 'payment_success',
   PAYMENT_ERROR: 'payment_error',
};

export const FORM_FIELD_VALUES = {
   OPTION_VALUE_DR: "Dr",
   OPTION_VALUE_I: 'I',
   OPTION_VALUE_II: 'II',
   OPTION_VALUE_III: 'III',
   OPTION_VALUE_IV: 'IV',
   OPTION_VALUE_JR: 'Jr',
   OPTION_VALUE_MISS: "Miss",
   OPTION_VALUE_MR: "Mr",
   OPTION_VALUE_MRS: "Mrs",
   OPTION_VALUE_MS: "Ms",
   OPTION_VALUE_SECURITY_QUESTION_1: '1',
   OPTION_VALUE_SECURITY_QUESTION_2: '2',
   OPTION_VALUE_SECURITY_QUESTION_3: '3',
   OPTION_VALUE_SECURITY_QUESTION_4: '4',
   OPTION_VALUE_SECURITY_QUESTION_5: '5',
   OPTION_VALUE_SECURITY_QUESTION_6: '6',
   OPTION_VALUE_SECURITY_QUESTION_7: '7',
   OPTION_VALUE_SR: 'Sr',
   OPTION_VALUE_V: 'V'
};

export const FORM_DEFAULTS = {
   SCOUNTRY: '840 UNITED STATES',
};