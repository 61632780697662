import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import SelectBox from "../../shared/components/formik/SelectBox";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import CheckboxFieldSingle from "../../shared/components/formik/CheckboxFieldSingle";
import { LOCALIZATION_FORMS, LOCALIZATION_PAYMENT } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import { STATES } from "../../../modules/objects/states";

const validationSchemas = {
   default: Yup.object().shape({
      shippingAddressStreet: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      shippingAddressCity: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      shippingAddressState: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      shippingAddressZip: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      shippingAddressName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class PaymentShipping extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.PAYMENT_SHIPPING,
      };
   }
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.PAYMENT_REVIEW,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: PAGES.PAYMENT_REVIEW
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               {this.props.initialValues.page !== PAGES.REGISTER_ADDRESS_EDIT && (
                  <div className="row">
                     <div className="col-12">
                        <h1>{LOCALIZATION_PAYMENT.HEADING_SHIPPING}</h1>
                     </div>
                  </div>
               )}
               {this.makeDisabled}
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange }) => (
                           <Form>
                              <div className="row mt-3">
                                 <div className="col-12">
                                    <div className="sep py-3">
                                       <Field
                                          id="shipToBilling"
                                          name="shipToBilling"
                                          label={LOCALIZATION_PAYMENT.SHIP_TO_BILLING}
                                          className="checkbox-field"
                                          component={CheckboxFieldSingle}
                                       />
                                    </div>
                                 </div>
                              </div>
                              <div className={`row form-box ${values.shipToBilling ? "disabled-form" : ""}`}>
                                 <div className="col-12">
                                    <Field
                                       name="shippingAddressName"
                                       label={LOCALIZATION_PAYMENT.LABEL_NAME}
                                       placeholder={LOCALIZATION_PAYMENT.PLACEHOLDER_NAME}
                                       required={true}
                                       component={InputTextField}
                                       readOnly={values.shipToBilling}
                                       type="text"
                                       autoFocus={this.props.shipToBilling ? undefined : "autofocus"}
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="shippingAddressStreet"
                                       label={LOCALIZATION_FORMS.LABEL_STREET_ADDRESS}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_STREET_ADDRESS}
                                       required={true}
                                       component={InputTextField}
                                       readOnly={values.shipToBilling}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="shippingAddressApt"
                                       label={LOCALIZATION_FORMS.LABEL_APT}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_APT}
                                       component={InputTextField}
                                       readOnly={values.shipToBilling}
                                       className="py-2"
                                       type="text"
                                       inputClass="simple"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="shippingAddressCity"
                                       label={LOCALIZATION_FORMS.LABEL_CITY}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_CITY}
                                       required={true}
                                       component={InputTextField}
                                       readOnly={values.shipToBilling}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="shippingAddressState"
                                       label={LOCALIZATION_FORMS.LABEL_STATE}
                                       required={true}
                                       component={SelectBox}
                                       readOnly={values.shipToBilling}
                                       className="show-required fancy-select py-2"
                                       floatingLabel={true}
                                       inputClass="fancy"
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">* {LOCALIZATION_FORMS.LABEL_STATE}</option>
                                       {STATES &&
                                          Object.keys(STATES).map(function (key) {
                                             return (
                                                <option value={key} key={key}>
                                                   {STATES[key]}
                                                </option>
                                             );
                                          })}
                                    </Field>
                                    <Field
                                       name="shippingAddressZip"
                                       label={LOCALIZATION_FORMS.LABEL_ZIP_CODE}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_ZIP_CODE}
                                       required={true}
                                       component={InputTextField}
                                       readOnly={values.shipToBilling}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row button-box">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary btn-save-profile">
                                          {LOCALIZATION_PAYMENT.BUTTON_SAVE_SHIPPING_ADDRESS}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentShipping;
