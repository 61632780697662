import React, { Component } from "react";
import GlobalHeader from "../shared/components/GlobalHeader";
import ManagePanel from "./components/ManagePanel";
import { FORM_DEFAULTS } from "../../modules/config/constants";
import { LOCALIZATION_MANAGE } from "../../modules/config/localization";

class Manage extends Component {
   handleAddressEdit = () => {
      this.props.history.push({
         pathname: '/profile'
      })
   };
   constructor(props) {
      super(props);
      this.state = {
         // initialValues should be pulled in from DB
         initialValues: {
            progress: 0,
            tfName: "Nicole",
            tlName: "Anderson",
            tmI: "",
            stitle: "",
            ssuffix: "",
            temail: "nanderson@gmail.com",
            sphoneType: "",
            tphone: "(888) 888-8000",
            text: "",
            tmobile: "",
            taddress: "3100 Buffalo Creek Rd",
            tapt: "",
            tcity: "Arlington",
            sstate: "VA",
            tzip: "22201",
            scountry: FORM_DEFAULTS.SCOUNTRY,
         },
      };
   }
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader showLogo={true} hideSubtitle={true} headerTitleText={LOCALIZATION_MANAGE.TITLE} />
            </div>
            <div className="subsection main-container profile-container">
               <div className="subsection page-panel">
                  <ManagePanel addressEdit={this.handleAddressEdit} onSubmit={this.handleSubmit} initialValues={this.state.initialValues} />
               </div>
            </div>
         </>
      );
   }
}

export default Manage;
