import React from 'react';
import AppRouter from './AppRouter';
import "./scss/App.scss";

function App() {
    return (
        <AppRouter />
    );
}

export default App;
