import React, { Component } from "react";
import { PAGES, FORM_DEFAULTS } from "../../modules/config/constants";
import { LOCALIZATION_IDGUEST } from "../../modules/config/localization";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProgressBar from "../shared/components/ProgressBar";
import IDGuestAddress from "./components/IDGuestAddress";
import IDGuestAddressVerify from "./components/IDGuestAddressVerify";
import IDGuestIneligible from "./components/IDGuestIneligible";

class IDGuest extends Component {
   constructor(props) {
      super(props);
      this.state = {
         initialValues: {
            page: PAGES.IDGUEST_ADDRESS,
            backPage: PAGES.IDGUEST_ADDRESS,
            progress: 0,
            taddress: "",
            tapt: "",
            tcity: "",
            sstate: "",
            tzip: "",
            scountry: FORM_DEFAULTS.SCOUNTRY,
            completedForms: [],
         },
      };
   }
   handlePageChange = values => {
      this.setState({ initialValues: values }, function(){
         if (values.page === 'trigger_register'){
            this.props.history.push({
               pathname: '/',
               state: { addressData: values }
            })
         }
      });
   };
   render() {
      return (
         <>
            <div className="section overhead">
               <GlobalHeader
                  handlePageChange={this.handlePageChange}
                  headerTitleText={LOCALIZATION_IDGUEST.TITLE}
                  initialValues={this.state.initialValues}
               />
               <ProgressBar progress={this.state.initialValues.progress} />
            </div>
            <div className="subsection main-container register-container">
               <div className="subsection page-panel">
                  {(this.state.initialValues.page === PAGES.IDGUEST_ADDRESS ||
                     this.state.initialValues.page === PAGES.IDGUEST_ADDRESS_EDIT) && (
                     <IDGuestAddress initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDGUEST_ADDRESS_VERIFY && (
                     <IDGuestAddressVerify initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
                  {this.state.initialValues.page === PAGES.IDGUEST_INELIGIBLE && (
                     <IDGuestIneligible initialValues={this.state.initialValues} onSubmit={this.handlePageChange} />
                  )}
               </div>
            </div>
         </>
      );
   }
}

export default IDGuest;
