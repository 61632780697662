import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import SelectBox from "../../shared/components/formik/SelectBox";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { LOCALIZATION_FORMS, LOCALIZATION_PAYMENT } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import { STATES } from "../../../modules/objects/states";

const validationSchemas = {
   default: Yup.object().shape({
      billingAddressStreet: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      billingAddressCity: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      billingAddressState: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      billingAddressZip: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      billingAddressName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class PaymentBilling extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.PAYMENT_BILLING,
      };
   }
   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.PAYMENT_REVIEW,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               {this.props.initialValues.page !== PAGES.REGISTER_ADDRESS_EDIT && (
                  <div className="row">
                     <div className="col-12">
                        <h1>{LOCALIZATION_PAYMENT.HEADING_BILLING}</h1>
                     </div>
                  </div>
               )}
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ handleBlur, handleChange }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="billingAddressName"
                                       label={LOCALIZATION_PAYMENT.LABEL_NAME}
                                       placeholder={LOCALIZATION_PAYMENT.PLACEHOLDER_NAME}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       autoFocus="autofocus"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="billingAddressStreet"
                                       label={LOCALIZATION_FORMS.LABEL_STREET_ADDRESS}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_STREET_ADDRESS}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="billingAddressApt"
                                       label={LOCALIZATION_FORMS.LABEL_APT}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_APT}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="billingAddressCity"
                                       label={LOCALIZATION_FORMS.LABEL_CITY}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_CITY}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="billingAddressState"
                                       label={LOCALIZATION_FORMS.LABEL_STATE}
                                       required={true}
                                       component={SelectBox}
                                       className="show-required fancy-select mb-0 py-2"
                                       floatingLabel={true}
                                       inputClass="fancy"
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">* {LOCALIZATION_FORMS.LABEL_STATE}</option>
                                       {STATES &&
                                          Object.keys(STATES).map(function (key) {
                                             return (
                                                <option value={key} key={key}>
                                                   {STATES[key]}
                                                </option>
                                             );
                                          })}
                                    </Field>
                                    <Field
                                       name="billingAddressZip"
                                       label={LOCALIZATION_FORMS.LABEL_ZIP_CODE}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_ZIP_CODE}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required py-2"
                                       floatingLabel={true}
                                       onChange={(e) => {
                                          handleChange(e);
                                       }}
                                       handleBlur={(e) => {
                                          handleBlur(e);
                                       }}
                                    />
                                 </div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_PAYMENT.BUTTON_SAVE_BILLING_ADDRESS}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentBilling;
