import React, { Component } from "react";
import { PAGES } from "../../modules/config/constants";
import GlobalHeader from "../shared/components/GlobalHeader";
import ProgressBar from "../shared/components/ProgressBar";
import PaymentReview from "./components/PaymentReview";
import PaymentCart from "./components/PaymentCart";
import PaymentShipping from "./components/PaymentShipping";
import PaymentBilling from "./components/PaymentBilling";
import PaymentMethod from "./components/PaymentMethod";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentError from "./components/PaymentError";
import Spinner from "../shared/components/Spinner";
import Warning from "../../static/img/warning-grey.png";
import { LOCALIZATION_PAYMENT } from "../../modules/config/localization";
import queryString from 'query-string';

class Payment extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isLoaded: false,
         errorLoading: false,
         cartURL: "https://mar.ext.optimo-it.com/cns-store/api/store/cart2",
         orderCompleteURL: "https://mar.ext.optimo-it.com/cns-store/api/store/completeOrder",
         token: "",
         initialValues: {
            progress: 0,
            page: PAGES.PAYMENT_REVIEW,
            backPage: PAGES.PAYMENT_REVIEW,
            verificationCode: "",
            completedForms: [],
            acceptOptIn: false,
            enableMobileDevice: false,
            shipToBilling: true,
            billingAddressName: "Nicole Anderson",
            billingAddressStreet: "3100 Buffalo Creek Rd",
            billingAddressApt: "",
            billingAddressCity: "Arlington",
            billingAddressState: "VA",
            billingAddressZip: "22201",
            shippingAddressName: "Nicole Anderson",
            shippingAddressStreet: "3100 Buffalo Creek Rd",
            shippingAddressApt: "",
            shippingAddressCity: "Arlington",
            shippingAddressState: "VA",
            shippingAddressZip: "22201",
            paymentMethodName: "",
            paymentMethodCCNumber: "",
            paymentMethodExpDate: "",
            paymentMethodCVV: "",
            cartItems: [],
            response: {},
            urlQueryString: queryString.parse(this.props.location.search),
         },
      };
   }

   getData() {
      const axios = require("axios");
      const searchParams = new URLSearchParams(window.location.search);
      const getToken = searchParams.get('token');
      this.setState({
         token: getToken
      });
      return axios.get(`${this.state.cartURL}?token=${getToken}`);
   }

   componentDidMount() {
      this.getData()
         .then((response) => {
            let info = response.data;
            this.setState({
               isLoaded: true,
               initialValues: {
                  progress: 0,
                  page: PAGES.PAYMENT_REVIEW,
                  backPage: PAGES.PAYMENT_REVIEW,
                  verificationCode: "",
                  completedForms: [],
                  acceptOptIn: false,
                  enableMobileDevice: false,
                  shipToBilling: true,
                  billingAddressName: `${info.billingAddress.firstName} ${info.billingAddress.lastName}`,
                  billingAddressStreet: info.billingAddress.address2,
                  billingAddressApt: info.billingAddress.address1,
                  billingAddressCity: info.billingAddress.city,
                  billingAddressState: info.billingAddress.state,
                  billingAddressZip: info.billingAddress.zip5,
                  shippingAddressName: `${info.shippingAddress.firstName} ${info.shippingAddress.lastName}`,
                  shippingAddressStreet: info.shippingAddress.address2,
                  shippingAddressApt: info.shippingAddress.address1,
                  shippingAddressCity: info.shippingAddress.city,
                  shippingAddressState: info.shippingAddress.state,
                  shippingAddressZip: info.shippingAddress.zip5,
                  paymentMethodName: "",
                  paymentMethodCCNumber: "",
                  paymentMethodExpDate: "",
                  paymentMethodCVV: "",
                  cartItems: info.cartItems,
                  urlQueryString: queryString.parse(this.props.location.search),
               },
            });
         })
         .catch((error) => {
            console.log("error pulling data from API");
            this.setState({
               errorLoading: true,
            });
         });
   }

   toTitleCase(str) {
      let text = "";
      str.search("-") === -1
         ? (text = str
              .toLowerCase()
              .split(" ")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join(" "))
         : (text = str
              .toLowerCase()
              .split("-")
              .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
              .join("-"));
      return text;
   }

   handlePageChange = (values) => {
      this.setState({ initialValues: values });
   };
   handleBillingAddressEdit = (values) => {
      this.setState({ initialValues: values, page: PAGES.PAYMENT_BILLING }, function () {});
   };
   handleShippingAddressEdit = (values) => {
      this.setState({ initialValues: values, page: PAGES.PAYMENT_SHIPPING }, function () {});
   };
   render() {
      const { isLoaded, errorLoading } = this.state;
      if (isLoaded) {
         return (
            <>
               <div className="section overhead">
                  <GlobalHeader
                     hideSubtitle={true}
                     handlePageChange={this.handlePageChange}
                     history={this.props.history || undefined}
                     showLogo={true}
                     headerTitleText={LOCALIZATION_PAYMENT.TITLE}
                     initialValues={this.state.initialValues}
                  />
                  <ProgressBar progress={this.state.initialValues.progress} />
               </div>
               <div className={`subsection recovery-container main-container page-` + this.state.initialValues.page}>
                  <div className="subsection page-panel">
                     {this.state.initialValues.page === PAGES.PAYMENT_REVIEW && (
                        <PaymentReview
                           onSubmit={this.handlePageChange}
                           initialValues={this.state.initialValues}
                           cartURL={this.state.cartURL}
                           orderCompleteURL={this.state.orderCompleteURL}
                           token={this.state.token}
                        />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_CART && (
                        <PaymentCart onSubmit={this.handlePageChange} initialValues={this.state.initialValues} />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_METHOD && (
                        <PaymentMethod onSubmit={this.handlePageChange} initialValues={this.state.initialValues} />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_SHIPPING && (
                        <PaymentShipping onSubmit={this.handlePageChange} initialValues={this.state.initialValues} />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_BILLING && (
                        <PaymentBilling onSubmit={this.handlePageChange} initialValues={this.state.initialValues} />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_SUCCESS && (
                        <PaymentSuccess onSubmit={this.handlePageChange} initialValues={this.state.initialValues} />
                     )}
                     {this.state.initialValues.page === PAGES.PAYMENT_ERROR && (
                        <PaymentError
                           onSubmit={this.handlePageChange}
                           initialValues={this.state.initialValues}
                           cartURL={this.state.cartURL}
                           orderCompleteURL={this.state.orderCompleteURL}
                           token={this.state.token}
                        />
                     )}
                  </div>
               </div>
            </>
         );
      } else if (errorLoading) {
         return (
            <>
               <div className="section overhead">
                  <GlobalHeader
                     hideSubtitle={true}
                     handlePageChange={this.handlePageChange}
                     history={this.props.history || undefined}
                     showLogo={true}
                     headerTitleText={LOCALIZATION_PAYMENT.TITLE}
                     initialValues={this.state.initialValues}
                  />
                  <ProgressBar progress={this.state.initialValues.progress} />
               </div>
               <div className={`subsection recovery-container main-container page-` + this.state.initialValues.page}>
                  <div className="subsection page-panel">
                     <div className="container">
                        <div className="row">
                           <div className="col-12">
                              <p className="text-center my-3">
                                 <img src={Warning} alt="Warning" className="warning-icon" />
                              </p>
                              <h1 className="text-center color-body mt-0 mb-2">
                                 {LOCALIZATION_PAYMENT.TEXT_CARTAPI_ERROR_HEADING}
                              </h1>
                              <p className="text-center color-body">
                                 {LOCALIZATION_PAYMENT.TEXT_CARTAPI_ERROR_SUBHEADING}
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>
         );
      } else {
         return (
            <>
               <div className="section overhead">
                  <GlobalHeader
                     hideSubtitle={true}
                     handlePageChange={this.handlePageChange}
                     history={this.props.history || undefined}
                     showLogo={true}
                     headerTitleText={LOCALIZATION_PAYMENT.TITLE}
                     initialValues={this.state.initialValues}
                  />
                  <ProgressBar progress={this.state.initialValues.progress} />
               </div>
               <div className={`subsection recovery-container main-container page-` + this.state.initialValues.page}>
                  <div className="subsection page-panel">
                     <div className="container">
                        <div className="row">
                           <div className="col-12">
                              <h1 className="text-center">
                                 <Spinner />
                              </h1>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </>
         );
      }
   }
}

export default Payment;
