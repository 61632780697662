import React, { Component } from "react";

class ProgressBar extends Component {
   render() {
      return (
         <div className="subsection progress-bar-row">
            <div className="row">
               <div className="col-12">
                  <div className="subsection progress-bar-container">
                     <div className="progress-bar-indicator" style={{ width: this.props.progress + "%" }}></div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}
export default ProgressBar;
