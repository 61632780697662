import React, { Component } from "react";
import { Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_REGISTER,
   LOCALIZATION_SHARED,
} from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";

class AddressVerify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.REGISTER_ADDRESS_VERIFY,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({ ...values, page: PAGES.REGISTER_CONGRATS, completedForms: this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1 ? arrNewCF : this.props.initialValues.completedForms, progress: 100 });
   };
   handleAddressEdit = () => {
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.REGISTER_ADDRESS_EDIT,
         backPage: PAGES.REGISTER_ADDRESS_VERIFY,
         progress: 87.5,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_REGISTER.PAGE_TITLE_ADDRESS_VERIFY}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row combo-box">
                                 <div className="col-12">
                                    <div className="row section-row">
                                       <div className="col-12">
                                          <p>
                                             <strong className="dark">
                                                {LOCALIZATION_REGISTER.TEXT_YOUR_DELIVERABLE_ADDRESS}
                                             </strong>
                                             <br />
                                             {LOCALIZATION_REGISTER.TEXT_VERIFIED_VALID_ADDRESS}
                                          </p>
                                       </div>
                                    </div>
                                    <div className="row section-row align-items-center">
                                       <div className="col-8">
                                          <div className="address-icon">
                                             <img src={CircleCheckGreen} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                                          </div>
                                          <div className="address-data">
                                             {this.props.initialValues.taddress}
                                             <br />
                                             {this.props.initialValues.tapt}
                                             {this.props.initialValues.tapt && <br />}
                                             {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                                             {this.props.initialValues.tzip}
                                          </div>
                                       </div>
                                       <div className="col-4 text-right">
                                          <button
                                             onClick={this.handleAddressEdit}
                                             type="button"
                                             className="btn btn-edit"
                                          >
                                             {LOCALIZATION_FORMS.BUTTON_EDIT}
                                          </button>
                                       </div>
                                    </div>
                                    <div className="row section-row">
                                       <div className="col-12">
                                          <div className="sep">
                                             <p>
                                                {LOCALIZATION_REGISTER.TEXT_UPDATE_NOTICE}
                                                <a
                                                   className="arrow-link"
                                                   href="https://moversguide.usps.com"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                   https://moversguide.usps.com
                                                </a>
                                             </p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">{LOCALIZATION_REGISTER.LINK_READ_OUR_PRIVACY_STATEMENT}</div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_FORMS.BUTTON_NEXT}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default AddressVerify;
