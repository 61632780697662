import React, { Component } from "react";
import { Form, Formik } from "formik";
import { LOCALIZATION_FORMS, LOCALIZATION_PAYMENT } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import Amex from "../../../static/img/amex.svg";
import Visa from "../../../static/img/visa.svg";
import MasterCard from "../../../static/img/mastercard.svg";
import Discover from "../../../static/img/discover.svg";
import Diners from "../../../static/img/diners.svg";
import JCB from "../../../static/img/jcb.svg";
import Cart from "../../../static/img/icon-cart.svg";
import Next from "../../../static/img/forward-carrat-blue.svg";

class PaymentReview extends Component {
   handleBillingAddressEdit = () => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.PAYMENT_BILLING,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };
   handleShippingAddressEdit = () => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.PAYMENT_SHIPPING,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };
   handlePaymentMethodEdit = () => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.PAYMENT_METHOD,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };
   viewCart = () => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...this.props.initialValues,
         page: PAGES.PAYMENT_CART,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
      });
   };

   getFirstName = (str) => {
      return str.split(" ")[0];
   };

   getLastName = (str) => {
      return str.split(" ")[1];
   };

   getExpMonth = (str) => {
      return str.substring(0, 2);
   };

   getExpYear = (str) => {
      return str.substring(2, 4);
   };

   handleSubmit = (values) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      const axios = require("axios");
      const { orderCompleteURL, token } = this.props;
      axios
         .post(`${orderCompleteURL}?token=${token}`, {
            token: token,
            shippingAddress: {
               firstName: this.getFirstName(this.props.initialValues.shippingAddressName),
               lastName: this.getLastName(this.props.initialValues.shippingAddressName),
               address1: this.props.initialValues.shippingAddressApt,
               address2: this.props.initialValues.shippingAddressStreet,
               city: this.props.initialValues.shippingAddressCity,
               state: this.props.initialValues.shippingAddressState,
               zip5: this.props.initialValues.shippingAddressZip,
            },
            payment: {
               type: this.getCardType,
               number: this.props.initialValues.paymentMethodCCNumber,
               securityCode: this.props.initialValues.paymentMethodCVV,
               expirationMonth: parseInt(this.getExpMonth(this.props.initialValues.paymentMethodExpDate), 10),
               expirationYear: parseInt(`20${this.getExpYear(this.props.initialValues.paymentMethodExpDate)}`, 10),
            },
         })
         .then((response) => {
            //console.log(response.data);
            switch (response.data.status) {
               case 0:
                  this.props.onSubmit({
                     ...values,
                     response: response.data,
                     page: PAGES.PAYMENT_SUCCESS,
                     completedForms:
                        this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
                           ? arrNewCF
                           : this.props.initialValues.completedForms,
                     backPage: this.state.currentForm,
                     progress: 100,
                  });
                  break;
               case 1:
               case 2:
               case 3:
               case 4:
               case 5:
               case 6:
               case 7:
               default:
                  this.props.onSubmit({
                     ...values,
                     response: response.data,
                     page: PAGES.PAYMENT_ERROR,
                     completedForms:
                        this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
                           ? arrNewCF
                           : this.props.initialValues.completedForms,
                     backPage: this.state.currentForm,
                  });
                  break;
            }
         })
         .catch((response) => {
            this.props.onSubmit({
               ...values,
               response: response.data,
               page: PAGES.PAYMENT_ERROR,
               completedForms:
                  this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
                     ? arrNewCF
                     : this.props.initialValues.completedForms,
               backPage: this.state.currentForm,
            });
         });
   };
   getQuantity = this.props.initialValues.cartItems.reduce((acc, obj) => {
      return acc + obj.quantity;
   }, 0);
   renderQuantity = this.getQuantity > 1 ? `${this.getQuantity} Items` : `${this.getQuantity} Item`;

   renderTotalPrice = this.props.initialValues.cartItems.reduce((acc, obj) => {
      return acc + obj.price * obj.quantity;
   }, 0);

   getCardType =
      this.props.initialValues.paymentMethodCCNumber.slice(0, 2) === LOCALIZATION_PAYMENT.DINERS_FIRST_DIGITS
         ? LOCALIZATION_PAYMENT.DINERS
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 2) === LOCALIZATION_PAYMENT.JCB_FIRST_DIGITS
         ? LOCALIZATION_PAYMENT.JCB
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.AMEX_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.AMEX
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.VISA_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.VISA
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.MASTERCARD_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.MASTERCARD
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.DISCOVER_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.DISCOVER
         : LOCALIZATION_PAYMENT.OTHER;

   renderCartItems = this.props.initialValues.cartItems.map((item, index) => {
      return item.quantity > 0 ? (
         <li className="fine-print" key={index}>{`${item.name} ${item.description}  x${item.quantity}`}</li>
      ) : null;
   });
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.PAYMENT_REVIEW,
      };
   }
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_PAYMENT.HEADING_REVIEW}</h1>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        render={() => (
                           <Form>
                              <div className="row align-items-center sep-above py-3">
                                 <div className="col-8">
                                    <div className="address-data-idguest">
                                       <p>
                                          <span className="fs-11">{LOCALIZATION_PAYMENT.LABEL_BILLING_ADDRESS}</span>
                                          <br />
                                          {this.props.initialValues.billingAddressName}
                                          <br />
                                          {this.props.initialValues.billingAddressStreet}
                                          <br />
                                          {this.props.initialValues.billingAddressApt}
                                          {this.props.initialValues.billingAddressApt && <br />}
                                          {this.props.initialValues.billingAddressCity},{" "}
                                          {this.props.initialValues.billingAddressState}{" "}
                                          {this.props.initialValues.billingAddressZip}
                                       </p>
                                    </div>
                                 </div>
                                 <div className="col-4 text-right">
                                    <button
                                       onClick={this.handleBillingAddressEdit}
                                       type="button"
                                       className="btn btn-edit"
                                    >
                                       {LOCALIZATION_FORMS.BUTTON_EDIT}
                                    </button>
                                 </div>
                              </div>
                              <div className="row align-items-center sep-above py-3">
                                 <div className="col-8">
                                    <div className="address-data-idguest">
                                       <p>
                                          <span className="fs-11">{LOCALIZATION_PAYMENT.LABEL_SHIPPING_ADDRESS}</span>
                                          <br />
                                          {this.props.initialValues.shipToBilling ? (
                                             <>{LOCALIZATION_PAYMENT.SHIP_TO_BILLING}</>
                                          ) : (
                                             <>
                                                {this.props.initialValues.shippingAddressName}
                                                <br />
                                                {this.props.initialValues.shippingAddressStreet}
                                                <br />
                                                {this.props.initialValues.shippingAddressApt}
                                                {this.props.initialValues.shippingAddressApt && <br />}
                                                {this.props.initialValues.shippingAddressCity},{" "}
                                                {this.props.initialValues.shippingAddressState}{" "}
                                                {this.props.initialValues.shippingAddressZip}
                                             </>
                                          )}
                                       </p>
                                    </div>
                                 </div>
                                 <div className="col-4 text-right">
                                    <button
                                       onClick={this.handleShippingAddressEdit}
                                       type="button"
                                       className="btn btn-edit"
                                    >
                                       {LOCALIZATION_FORMS.BUTTON_EDIT}
                                    </button>
                                 </div>
                              </div>
                              <div className="row align-items-center sep-above py-3">
                                 <div className="col-8">
                                    <p className="fs-11 mb-2">{LOCALIZATION_PAYMENT.LABEL_PAYMENT_METHOD}</p>

                                    {this.props.initialValues.paymentMethodCCNumber !== "" ? (
                                       <div className="row">
                                          <div className="col-3">
                                             <div className="credit-card">
                                                <img
                                                   src={
                                                      this.getCardType === LOCALIZATION_PAYMENT.AMEX
                                                         ? Amex
                                                         : this.getCardType === LOCALIZATION_PAYMENT.VISA
                                                         ? Visa
                                                         : this.getCardType === LOCALIZATION_PAYMENT.MASTERCARD
                                                         ? MasterCard
                                                         : this.getCardType === LOCALIZATION_PAYMENT.DISCOVER
                                                         ? Discover
                                                         : this.getCardType === LOCALIZATION_PAYMENT.DINERS
                                                         ? Diners
                                                         : this.getCardType === LOCALIZATION_PAYMENT.JCB
                                                         ? JCB
                                                         : LOCALIZATION_PAYMENT.OTHER
                                                   }
                                                   alt={this.getCardType}
                                                />
                                             </div>
                                          </div>
                                          <div className="col-9 px-2">
                                             <span className="lh-28">{`${this.getCardType} ${
                                                LOCALIZATION_PAYMENT.ENDING
                                             }${this.props.initialValues.paymentMethodCCNumber.slice(-2)}`}</span>
                                          </div>
                                       </div>
                                    ) : (
                                       <p className="fs-14">{LOCALIZATION_PAYMENT.TEXT_ADD_PAYMENT}</p>
                                    )}
                                 </div>
                                 <div className="col-4 text-right">
                                    <button
                                       onClick={this.handlePaymentMethodEdit}
                                       type="button"
                                       className="btn btn-edit"
                                    >
                                       {LOCALIZATION_FORMS.BUTTON_EDIT}
                                    </button>
                                 </div>
                              </div>
                              <div className="row align-items-center sep-above py-3">
                                 <div className="col-12" onClick={this.viewCart}>
                                    <p className="fs-11 mb-2">{LOCALIZATION_PAYMENT.LABEL_ITEMS}</p>
                                    <div className="row">
                                       <div className="col-2">
                                          <img src={Cart} alt="Shopping Cart Icon" />
                                       </div>
                                       <div className="col-8">
                                          <span className="font-bold">
                                             {this.getQuantity === 0 ? "Your cart is empty" : this.renderQuantity}
                                          </span>
                                          <ul className="list-unstyled">{this.renderCartItems}</ul>
                                       </div>
                                       <div className="col-2 p-4 text-right">
                                          <img className="img-next" src={Next} alt="Go to Shopping Cart" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <p className="text-center total-price m-0 py-2">
                                          {LOCALIZATION_PAYMENT.TOTAL}
                                          <span className="color-blue font-bold">{`: $${this.renderTotalPrice.toFixed(
                                             2
                                          )}`}</span>
                                       </p>
                                       <button type="submit" className="btn btn-primary">
                                          {LOCALIZATION_PAYMENT.BUTTON_PLACE_ORDER}
                                       </button>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentReview;
