import React, { Component } from "react";
import InputTextField from "../../shared/components/formik/InputTextField";
import SelectBox from "../../shared/components/formik/SelectBox";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import isEmpty from "lodash/isEmpty";
import { LOCALIZATION_FORMS, LOCALIZATION_REGISTER, LOCALIZATION_SHARED } from "../../../modules/config/localization";
import { PAGES, FORM_FIELD_VALUES } from "../../../modules/config/constants";

const validationSchemas = {
   default: Yup.object().shape({
      tfName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
      tlName: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class Name extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.REGISTER_NAME
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.REGISTER_EMAIL,
         completedForms: this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1 ? arrNewCF : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 50,
      });
   };
   handleAccordionToggle = () => {
      this.setState({ showMoreOptions: !this.state.showMoreOptions });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_REGISTER.PAGE_TITLE_NAME}</h1>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <Field
                                       name="stitle"
                                       label={LOCALIZATION_FORMS.LABEL_TITLE}
                                       floatingLabel={true}
                                       component={SelectBox}
                                       className={`fancy-select mb-0 ${
                                          this.state.showMoreOptions ? "hidden-option-visible" : "hidden"
                                       }`}
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">{LOCALIZATION_FORMS.OPTION_TEXT_TITLE}</option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MRS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MRS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_MISS}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_MISS}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_DR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_DR}
                                       </option>
                                    </Field>
                                    <Field
                                       name="tfName"
                                       label={LOCALIZATION_FORMS.LABEL_FIRST_NAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_FIRST_NAME}
                                       required={true}
                                       autoFocus="autofocus"
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tmI"
                                       label={LOCALIZATION_FORMS.LABEL_MI}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_MI}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className={this.state.showMoreOptions ? "hidden-option-visible" : "hidden"}
                                       floatingLabel={true}
                                       maxLength={1}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="tlName"
                                       label={LOCALIZATION_FORMS.LABEL_LAST_NAME}
                                       placeholder={LOCALIZATION_FORMS.PLACEHOLDER_LAST_NAME}
                                       required={true}
                                       component={InputTextField}
                                       type="text"
                                       inputClass="simple"
                                       className="show-required"
                                       floatingLabel={true}
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       handleBlur={e => {
                                          handleBlur(e);
                                       }}
                                    />
                                    <Field
                                       name="ssuffix"
                                       label={LOCALIZATION_FORMS.LABEL_SUFFIX}
                                       floatingLabel={true}
                                       component={SelectBox}
                                       className={`fancy-select mb-0 ${
                                          this.state.showMoreOptions ? "hidden-option-visible" : "hidden"
                                       }`}
                                       inputClass="fancy"
                                       onChange={e => {
                                          handleChange(e);
                                       }}
                                       onBlur={handleBlur}
                                    >
                                       <option value="">{LOCALIZATION_FORMS.OPTION_TEXT_SUFFIX}</option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_JR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_JR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_SR}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_SR}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_I}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_I}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_II}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_II}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_III}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_III}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_IV}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_IV}
                                       </option>
                                       <option value={FORM_FIELD_VALUES.OPTION_VALUE_V}>
                                          {LOCALIZATION_FORMS.OPTION_TEXT_V}
                                       </option>
                                    </Field>
                                 </div>
                              </div>
                              <div className="row section-row">
                                 <div className="col-12">
                                    <button
                                       type="button"
                                       onClick={this.handleAccordionToggle}
                                       className={`btn-accordion btn-accordion-${
                                          this.state.showMoreOptions ? "open" : "closed"
                                       }`}
                                    >
                                       {this.state.showMoreOptions
                                          ? LOCALIZATION_SHARED.TEXT_SHOW_LESS_OPTIONS
                                          : LOCALIZATION_SHARED.TEXT_SHOW_MORE_OPTIONS}
                                    </button>
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Name;
