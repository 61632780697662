import React, { Component } from "react";
import { LOCALIZATION_PAYMENT, LOCALIZATION_SHARED } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";
import Amex from "../../../static/img/amex.svg";
import Visa from "../../../static/img/visa.svg";
import MasterCard from "../../../static/img/mastercard.svg";
import Discover from "../../../static/img/discover.svg";
import Diners from "../../../static/img/diners.svg";
import JCB from "../../../static/img/jcb.svg";
import Cart from "../../../static/img/icon-cart.svg";

class PaymentSuccess extends Component {
   constructor(props) {
      super(props);
      const queryString = this.props.initialValues.urlQueryString.returnUrl;
      const leadingChar = queryString.includes('?') ? '&' : '?';
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.PAYMENT_SUCCESS,
         redirectUrl: queryString + leadingChar + 'orderNumber=' + this.props.initialValues.response.orderNumber
      };
   }
   getQuantity = this.props.initialValues.cartItems.reduce((acc, obj) => {
      return acc + obj.quantity;
   }, 0);
   renderQuantity = this.getQuantity > 1 ? `${this.getQuantity} ${LOCALIZATION_PAYMENT.TEXT_ITEMS}` : `${this.getQuantity} ${LOCALIZATION_PAYMENT.TEXT_ITEM}`;

   renderTotalPrice = this.props.initialValues.cartItems.reduce((acc, obj) => {
      return acc + obj.price * obj.quantity;
   }, 0);

   getCardType =
      this.props.initialValues.paymentMethodCCNumber.slice(0, 2) === LOCALIZATION_PAYMENT.DINERS_FIRST_DIGITS
         ? LOCALIZATION_PAYMENT.DINERS
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 2) === LOCALIZATION_PAYMENT.JCB_FIRST_DIGITS
         ? LOCALIZATION_PAYMENT.JCB
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.AMEX_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.AMEX
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.VISA_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.VISA
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.MASTERCARD_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.MASTERCARD
         : this.props.initialValues.paymentMethodCCNumber.slice(0, 1) === LOCALIZATION_PAYMENT.DISCOVER_FIRST_DIGIT
         ? LOCALIZATION_PAYMENT.DISCOVER
         : LOCALIZATION_PAYMENT.OTHER;

   renderCartItems = this.props.initialValues.cartItems.map((item, index) => {
      return item.quantity > 0 ? (
         <li className="fine-print" key={index}>{`${item.name} ${item.description}  x${item.quantity}`}</li>
      ) : null;
   });

   componentDidMount(){
      window.location.href = this.state.redirectUrl;
   }

   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <p className="text-center">
                        <img src={CircleCheckGreen} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                     </p>
                     <h1 className="text-center green mb-3">{LOCALIZATION_PAYMENT.HEADING_SUCCESS}</h1>
                     <p className="text-center">{`${LOCALIZATION_PAYMENT.CONFIRMATION_NUM} ${this.props.initialValues.response.orderNumber}`}</p>
                     <div className="row align-items-center sep-above py-3">
                        <div className="col-8">
                           <div className="address-data-idguest">
                              <p>
                                 <span className="fs-11">{LOCALIZATION_PAYMENT.LABEL_SHIPPING_ADDRESS}</span>
                                 <br />
                                 {this.props.initialValues.shipToBilling ? (
                                    <>
                                       {this.props.initialValues.billingAddressName}
                                       <br />
                                       {this.props.initialValues.billingAddressStreet}
                                       <br />
                                       {this.props.initialValues.billingAddressApt}
                                       {this.props.initialValues.billingAddressApt && <br />}
                                       {this.props.initialValues.billingAddressCity},{" "}
                                       {this.props.initialValues.billingAddressState}{" "}
                                       {this.props.initialValues.billingAddressZip}
                                    </>
                                 ) : (
                                    <>
                                       {this.props.initialValues.shippingAddressName}
                                       <br />
                                       {this.props.initialValues.shippingAddressStreet}
                                       <br />
                                       {this.props.initialValues.shippingAddressApt}
                                       {this.props.initialValues.shippingAddressApt && <br />}
                                       {this.props.initialValues.shippingAddressCity},{" "}
                                       {this.props.initialValues.shippingAddressState}{" "}
                                       {this.props.initialValues.shippingAddressZip}
                                    </>
                                 )}
                              </p>
                           </div>
                        </div>
                     </div>
                     <div className="row align-items-center sep-above py-3">
                        <div className="col-8">
                           <p className="fs-11 mb-2">{LOCALIZATION_PAYMENT.LABEL_PAYMENT_METHOD}</p>
                           <div className="row">
                              <div className="col-3">
                                 <div className="credit-card">
                                    <img
                                       src={
                                          this.getCardType === LOCALIZATION_PAYMENT.AMEX
                                             ? Amex
                                             : this.getCardType === LOCALIZATION_PAYMENT.VISA
                                             ? Visa
                                             : this.getCardType === LOCALIZATION_PAYMENT.MASTERCARD
                                             ? MasterCard
                                             : this.getCardType === LOCALIZATION_PAYMENT.DISCOVER
                                             ? Discover
                                             : this.getCardType === LOCALIZATION_PAYMENT.DINERS
                                             ? Diners
                                             : this.getCardType === LOCALIZATION_PAYMENT.JCB
                                             ? JCB
                                             : LOCALIZATION_PAYMENT.OTHER
                                       }
                                       alt={this.getCardType}
                                    />
                                 </div>
                              </div>
                              <div className="col-9 px-2">
                                 <span className="lh-28">
                                    {this.getCardType} {LOCALIZATION_PAYMENT.ENDING} {this.props.initialValues.paymentMethodCCNumber.slice(-2)}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row align-items-center sep-above py-3">
                        <div className="col-12">
                           <p className="fs-11 mb-2">{LOCALIZATION_PAYMENT.LABEL_ITEMS}</p>
                           <div className="row">
                              <div className="col-2">
                                 <img src={Cart} alt={LOCALIZATION_SHARED.IMG_ALT_SHOPPING_CART} />
                              </div>
                              <div className="col-8">
                                 <span className="font-weight-bold">
                                    {this.getQuantity === 0 ? LOCALIZATION_PAYMENT.TEXT_EMPTY_CART : this.renderQuantity}
                                 </span>
                                 <ul className="list-unstyled">{this.renderCartItems}</ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row button-box button-box-shadow">
                        <div className="col-12">
                           <div className="button-bar clearfix">
                              <p className="text-center total-price m-0 py-2">
                                 {LOCALIZATION_PAYMENT.TOTAL}
                                 <span className="color-blue font-weight-bold">{`: $${this.renderTotalPrice.toFixed(
                                    2
                                 )}`}</span>
                              </p>
                              <a href={this.state.redirectUrl} className="btn btn-primary w-100">
                                 {LOCALIZATION_PAYMENT.BUTTON_DONE}
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default PaymentSuccess;
