import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import {
   LOCALIZATION_FORMS, 
   LOCALIZATION_IDREGISTERED,
   LOCALIZATION_SHARED
} from "../../../modules/config/localization";
import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";
import { PAGES } from "../../../modules/config/constants";
import CheckSuccess3 from "../../../static/img/check-success-3.svg";
import VerifyOnline from "../../../static/img/verify-online.svg";
import VerifyMail from "../../../static/img/verify-mail.svg";

const validationSchemas = {
   default: Yup.object().shape({
      verifyMethod: Yup.string().required(LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class IDRegisteredAddressVerify extends Component {
   constructor(props) {
      super(props);
      this.state = {
         currentForm: PAGES.IDREGISTERED_IDENTITY,
      };
   }
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: values.verifyMethod === '2' ? PAGES.IDREGISTERED_REQUEST_CODE_MAIL : PAGES.IDREGISTERED_REQUEST_CODE_ONLINE ,
         completedForms:
            this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1
               ? arrNewCF
               : this.props.initialValues.completedForms,
         backPage: this.state.currentForm,
         progress: 75,
      });
   };
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <h1>{LOCALIZATION_IDREGISTERED.PAGE_TITLE_IDENTITY}</h1>
                     <p>
                        <strong className="dark green">
                           {LOCALIZATION_IDREGISTERED.ADDRESS_ELIGIBLE}
                        </strong>
                     </p>
                  </div>
               </div>
               <div className="row align-items-center">
                  <div className="col-10">
                     <div className="address-data-idguest">
                        {this.props.initialValues.taddress}
                        <br />
                        {this.props.initialValues.tapt}
                        {this.props.initialValues.tapt && <br />}
                        {this.props.initialValues.tcity}, {this.props.initialValues.sstate}{" "}
                        {this.props.initialValues.tzip}
                     </div>
                  </div>
                  <div className="col-2 text-right">
                     <img src={CheckSuccess3} alt={LOCALIZATION_SHARED.IMG_ALT_CHECKMARK} />
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={({ values, handleBlur, handleChange, errors, dirty, isSubmitting, setFieldValue }) => (
                           <Form>
                              <div className="row form-box">
                                 <div className="col-12">
                                    <div className="row radio-row">
                                       <div className="col-12">
                                          <label
                                             className={[
                                                "custom-radio",
                                                values.verifyMethod === 1 ? "is-selected" : "",
                                             ].join(" ")}
                                          >
                                             <div className="row align-items-center">
                                                <div className="col-2 text-center">
                                                   <img src={VerifyOnline} alt="Verify Online" />
                                                </div>
                                                <div className="col-8">
                                                   <p>
                                                      <strong className="color-blue">{LOCALIZATION_IDREGISTERED.TEXT_VERIFY_IDENTITY_ONLINE}</strong><br />
                                                      {LOCALIZATION_IDREGISTERED.TEXT_ONLINE_VERIFICATION}
                                                   </p>
                                                </div>
                                                <div className="col-2 text-center">
                                                   <Field
                                                      name="verifyMethod"
                                                      component="input"
                                                      type="radio"
                                                      defaultChecked={values.verifyMethod === '1' || undefined}
                                                      value={1}
                                                   />{" "}
                                                   <span className="faux-radio"><span className="faux-radio-check"></span></span>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </div>
                                    <div className="row radio-row">
                                       <div className="col-12">
                                          <label
                                             className={[
                                                "custom-radio",
                                                values.verifyMethod === 2 ? "is-selected" : "",
                                             ].join(" ")}
                                          >
                                             <div className="row align-items-center">
                                                <div className="col-2 text-center">
                                                   <img src={VerifyMail} alt="Verify by Mail" />
                                                </div>
                                                <div className="col-8">
                                                   <p>
                                                      <strong className="color-blue">{LOCALIZATION_IDREGISTERED.TEXT_REQUEST_CODE_BY_MAIL}</strong><br />
                                                      {LOCALIZATION_IDREGISTERED.TEXT_HAVING_YOUR_VERIFICATION_CODE_MAILED}
                                                   </p>
                                                </div>
                                                <div className="col-2 text-center">
                                                   <Field
                                                      name="verifyMethod"
                                                      component="input"
                                                      type="radio"
                                                      defaultChecked={values.verifyMethod === '2' || undefined}
                                                      value={2}
                                                   />
                                                   <span className="faux-radio"><span className="faux-radio-check"></span></span>
                                                </div>
                                             </div>
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              {!isSubmitting &&
                                 isEmpty(errors) &&
                                 (dirty ||
                                    this.props.initialValues.completedForms.indexOf(this.state.currentForm) !== -1) && (
                                    <div className="row button-box">
                                       <div className="col-12">
                                          <div className="button-bar clearfix">
                                             <button type="submit" className="btn btn-primary btn-next">
                                                {LOCALIZATION_FORMS.BUTTON_NEXT}
                                             </button>
                                          </div>
                                       </div>
                                    </div>
                                 )}
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default IDRegisteredAddressVerify;
