import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { LOCALIZATION_FORMS, LOCALIZATION_RECOVERY } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";

const validationSchemas = {
   default: Yup.object().shape({
      acceptOptIn: Yup.boolean()
         .required(LOCALIZATION_FORMS.VALIDATION_REQUIRED)
         .oneOf([true], LOCALIZATION_FORMS.VALIDATION_REQUIRED),
   }),
};

class RecoverySuccess extends Component {
   constructor(props) {
      super(props);
      this.state = {
         showMoreOptions: false,
         currentForm: PAGES.RECOVERY_SUCCESS,
      };
   }
   render() {
      return (
         <section className="subsection page-address">
            <div className="container">
               <div className="row mt-150">
                  <div className="col-12">
                     <p className="text-center">
                        <img src={CircleCheckGreen} alt="checkmark" />
                     </p>
                     <h1 className="text-center green">{LOCALIZATION_RECOVERY.SUCCESS_TITLE}</h1>
                     <p className="text-center">{LOCALIZATION_RECOVERY.SUCCESS_SUBTITLE} </p>
                     <Formik
                        initialValues={this.props.initialValues}
                        onSubmit={this.handleSubmit}
                        validationSchema={validationSchemas.default}
                        render={() => (
                           <Form>
                              <div className="row button-box button-box-shadow">
                                 <div className="col-12">
                                    <div className="button-bar clearfix">
                                       <Link to="/manage" className="btn btn-primary w-100">
                                          {LOCALIZATION_RECOVERY.SUCCESS_NEXT_BUTTON}
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </Form>
                        )}
                     />
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default RecoverySuccess;
