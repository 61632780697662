import React, { Component } from "react";
import { LOCALIZATION_FORMS,  LOCALIZATION_REGISTER } from "../../../modules/config/localization";
import { PAGES } from "../../../modules/config/constants";
import CircleCheckGreen from "../../../static/img/circle-check-green.svg";

class Congrats extends Component {
   handleSubmit = (values, { setSubmitting, resetForm }) => {
      let arrNewCF = this.props.initialValues.completedForms.push(this.state.currentForm);
      this.props.onSubmit({
         ...values,
         page: PAGES.REGISTER_RETURN,
         completedForms: this.props.initialValues.completedForms.indexOf(this.state.currentForm) === -1 ? arrNewCF : this.props.initialValues.completedForms,
         backPage: PAGES.REGISTER_CONGRATS,
         progress: 100,
      });
   };
   componentDidMount() {
      console.log('final form values:', this.props.initialValues);
   }
   render() {
      return (
         <section className="subsection">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="row">
                        <div className="col-12">
                           <div className="congrats">
                              <div className="congrats-inner">
                                 <p className="text-center">
                                    <img src={CircleCheckGreen} alt="checkmark" />
                                 </p>
                                 <h1 className="text-center green">
                                    {LOCALIZATION_REGISTER.CONGRATS}
                                    {LOCALIZATION_REGISTER.TEXT_YOURE_ALL_SET}, {this.props.initialValues.tfName}
                                 </h1>
                                 <p className="text-center">
                                    {LOCALIZATION_REGISTER.TEXT_TAP_DONE}
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row button-box button-box-shadow">
                        <div className="col-12">
                           <div className="button-bar clearfix">
                              <button type="button" className="btn btn-primary">
                                 {LOCALIZATION_FORMS.BUTTON_DONE}
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Congrats;
